import bb, { bubble, line } from "billboard.js";
import React, { useEffect, useMemo, useRef, useState } from "react";
import "billboard.js/dist/billboard.css";
import "./SelectionTimeSeriesLineChart.scss";
import { ErrorBoundary } from "./ErrorBoundary";
import { useTranslation } from "react-i18next";
import { Colors } from "../Theme";
import classNames from "classnames";
import { CircularProgress } from "@mui/material";
import * as d3 from "d3";
import { getTimeFormatValueForUser } from "../Enums/TimeFormat";
import moment from "moment";
import { getUserDateFormat } from "../AppState";
import { sleep } from "../Managers";

export interface ISelectedRange {
  startTime: Date;
  endTime: Date;
}

export interface ILineChartValue {
  x: Date;
  y: number | string | null;
  unit: string;
  triggeredTarget: number | null;
  color?: string;
}

export interface ISelectionLineChartProps {
  className?: string;
  data: ILineChartValue[];
  selectedRange?: ISelectedRange;
  onSelectedRange: (startDate: any, endDate: any) => void;
  valueName?: string;
  title?: string;
  loading?: boolean;
}

export const SelectionTimeSeriesLineChart: React.FC<ISelectionLineChartProps> = ({
  data,
  className,
  valueName = "Value",
  title,
  loading,
  onSelectedRange,
  selectedRange,
}) => {
  const [dataSeriesGroups, setDataSeriesGroups] = useState<string[]>([]);

  const chartRef = useRef(null);
  const chartInstance = useRef<bb.Chart | null>(null);

  const { t } = useTranslation("common");

  const dateFormat = getUserDateFormat();
  const timeFormat = getTimeFormatValueForUser();

  const isBoolean = data?.[0]?.unit.includes("/");

  const columns = useMemo(() => {
    const x1 = data.map((entry) => entry.x);
    const hasMoreThanOneSeries = data.some((entry) => entry.color);
    const columnsData = [];
    const seriesNames: React.SetStateAction<string[]> = [];

    columnsData.push(["x1", ...x1]);

    if (hasMoreThanOneSeries) {
      const groupedData = data.reduce<{ [key: string]: ILineChartValue[] }>((acc, entry) => {
        const color = entry.color || "default";
        if (!acc[color]) {
          acc[color] = [];
        }
        acc[color].push(entry);
        return acc;
      }, {});

      Object.entries(groupedData).forEach(([color, dataGroup]) => {
        const values = dataGroup.map((entry) => {
          if (isBoolean && typeof entry.y === "string") {
            return entry.unit
              .split("/")
              .map((item) => item.toUpperCase())
              .indexOf(entry.y.toUpperCase());
          }
          return entry.y;
        });

        const columnNameX = `x_${color}`;
        const columnNameY = `values_${color}`;

        seriesNames.push(color);

        const xValuesForColor = dataGroup.map((entry) => entry.x);

        columnsData.push([columnNameX, ...xValuesForColor]);
        columnsData.push([columnNameY, ...values]);
      });
    } else {
      const values = data.map((entry) => {
        if (isBoolean && typeof entry.y === "string") {
          return entry.unit
            .split("/")
            .map((item) => item.toUpperCase())
            .indexOf(entry.y.toUpperCase());
        }

        return entry.y;
      });
      columnsData.push(["values", ...values]);
    }

    if (hasMoreThanOneSeries) {
      setDataSeriesGroups(seriesNames);
    }

    return columnsData;
  }, [data]);

  const initializeChart = () => {
    let chart;
    try {
      chart = bb.generate({
        title: title ? { text: title } : undefined,
        bindto: chartRef.current,
        transition: {
          duration: 0,
        },
        data: {
          xs: {
            values: "x1",
            set_points: "x2",
            set_point_line: "x2",
            ...Object.fromEntries((dataSeriesGroups.length > 0 ? dataSeriesGroups : []).map((color) => [`values_${color}`, `x_${color}`])),
          },
          type: line(),
          types: {
            set_points: bubble(),
            set_point_line: line(),
          },
          colors: {
            values: "#55cdcd",
            set_points: Colors.warning,
            set_point_line: Colors.lightGray,
            ...Object.fromEntries((dataSeriesGroups.length > 0 ? dataSeriesGroups : []).map((color) => [`values_${color}`, color])),
          },
          columns,
        },
        // @see https://naver.github.io/billboard.js/release/latest/doc/Options.html#.line
        line: {
          // This is the default but I force-set it here in case that ever changes. We do not want 'null' entries "connected".
          connectNull: false,
          classes: ["values", "set_points", "values2", "values3", "values4"],
          point: ["values", "set_points", "set_point_line"],
        },
        point: {
          r: 1,
        },
        bubble: {
          maxR: 8,
        },
        legend: {
          show: false,
        },
        tooltip: {
          format: {
            title: (entry: Date) => moment(entry).format(`${dateFormat} ${timeFormat}`),
            name: (name: string) => (name === "values" ? valueName : t("common:setpoint")),
            value: (entry) => {
              let value = `${entry}`;
              if (isBoolean) {
                const unitValues = data[0].unit.split("/");
                return t(`common:select_options.${unitValues[entry]?.toLowerCase()}`).toUpperCase();
              }
              return `${value} ${data?.[0]?.unit || ""}`;
            },
          },
          grouped: false,
          show: true,
        },
        axis: {
          x: {
            tick: {
              fit: false,
              count: 5,
            },
            type: "timeseries",
          },
          y: {
            tick: {
              format: (x: number) => {
                if (isBoolean) {
                  const unitValues = data[0]?.unit.split("/");
                  return t(`common:select_options.${unitValues[x]?.toLowerCase()}`).toUpperCase();
                }
                return x.toString();
              },
            },
          },
        },
        grid: {
          y: {
            show: true,
          },
        },
        boost: {
          useCssRule: true,
        },
        interaction: {
          inputType: {
            mouse: true,
            touch: false,
          },
        },
        onrendered: async () => {
          await sleep(0); // this way we make sure chart will be already rendered
          if (!chartInstance.current || !chartRef.current) {
            return;
          }

          const chartInternal = (chartInstance.current as any).internal;

          const bbChartG = d3.select(chartRef.current).select(".bb-chart");

          const xScale = chartInternal.scale.x; // Get Billboard.js X scale

          const brush = d3.brushX().on("brush", handleBrush);

          bbChartG.select(".brush").remove(); // Remove old brush if any

          const brushGroup = bbChartG.append("g").attr("class", "brush").call(brush);

          if (selectedRange) {
            const x0 = selectedRange.startTime,
              x1 = selectedRange.endTime;

            const pixelSelection: [number, number] = [xScale(x0), xScale(x1)];

            brush.move(brushGroup, pixelSelection);
          }
        },
      });
    } catch (error) {
      console.error("Error generating chart:", error);
      return null;
    }
    chartInstance.current = chart;
  };

  const handleBrush = (event: any) => {
    if (!chartInstance.current || !event.selection) return;

    const xScale = (chartInstance.current as any).internal.scale.x;

    const [x0, x1] = event.selection; // Get selected range in pixels

    const domain = [xScale.invert(x0), xScale.invert(x1)]; // Map to data domain

    onSelectedRange(domain[0], domain[1]);
  };

  useEffect(() => {
    initializeChart();

    if (isBoolean) {
      // get all y values, filter to see if there are ON values, see if every value is null
      // if it's null, that means there aren't any ON values in this result set
      const allYValues = data.map((x) => x.y);
      const allContactTheSame = allYValues.every((v) => v === allYValues[0] || v === null || v === undefined);

      const count = allContactTheSame ? 1 : 3;
      const values = allContactTheSame ? [0] : [0, 1];

      chartInstance.current?.config("axis.y.tick.count", count, true);
      chartInstance.current?.config("axis.y.tick.values", values, true);
    }

    return () => chartInstance.current?.destroy();
  });

  return (
    <ErrorBoundary>
      <div className={classNames("line-chart-holder")}>
        <div ref={chartRef} className={className}></div>
        {loading ? <CircularProgress className="chart-load-indicator" /> : null}
      </div>
    </ErrorBoundary>
  );
};
