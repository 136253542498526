import React, { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { SelectionTimeSeriesLineChart } from "../../../../Components/SelectionTimeSeriesLineChart";
import { getNameSlug } from "../../../../Managers";
import { ILineChartValue } from "../../../../Components";
import { ISensor, ISensorDataNote } from "../../../../Managers/Types";
import { useTranslation } from "react-i18next";
import { Box, Stack, styled } from "@mui/material";
import { TextArea } from "../../../../Components/TextArea";
import "./NoteModeChart.scss";
import { NoteModeChartFooter } from "./NoteModeChartFooter";

export type INoteModeStep = "selectRange" | "addNote";

interface IChartHolderLayout {
  left: string;
  height: string;
  width: string;
}

interface INoteModeChart {
  chartData: ILineChartValue[];
  convertToHumidity: boolean;
  convertToTemperature: boolean;
  setNoteMode: React.Dispatch<React.SetStateAction<boolean>>;
  selectedNote?: ISensorDataNote;
  setSelectedNote: React.Dispatch<React.SetStateAction<ISensorDataNote | undefined>>;
  selectedSensor: ISensor;
}

export const NoteModeChart = forwardRef<HTMLDivElement, INoteModeChart>(
  ({ chartData, convertToHumidity, convertToTemperature, setNoteMode, selectedNote, setSelectedNote, selectedSensor }, ref) => {
    const [chartHolderLayout, setChartHolderLayout] = useState<IChartHolderLayout>();

    const { t } = useTranslation(["sensor_types"]);

    useEffect(() => {
      if (ref && (ref as any).current) {
        const zoomChartHolderRect = (ref as any).current.getBoundingClientRect();

        const left = zoomChartHolderRect.left + window.scrollX;
        const height = zoomChartHolderRect.height;
        const width = zoomChartHolderRect.width;

        setChartHolderLayout({ left, height, width });
      }
    }, [ref]);

    const [step, setStep] = useState<INoteModeStep>("selectRange");
    const [startTime, setStartTime] = useState<Date | null>(selectedNote?.startTime ? new Date(selectedNote?.startTime) : null);
    const [endTime, setEndTime] = useState<Date | null>(selectedNote?.endTime ? new Date(selectedNote?.endTime) : null);
    const [note, setNote] = useState<string>(selectedNote?.content ?? "");

    const onSelectedRange = useCallback(
      (newStartDate: Date, newEndDate: Date) => {
        setStartTime(newStartDate);
        setEndTime(newEndDate);
      },
      [setStartTime, setEndTime],
    );

    const chart = useMemo(
      () => (
        <SelectionTimeSeriesLineChart
          data={chartData}
          selectedRange={startTime && endTime ? { startTime, endTime } : undefined}
          onSelectedRange={onSelectedRange}
          valueName={
            convertToHumidity
              ? t("sensor_types.humidity")
              : convertToTemperature
              ? t("sensor_types.temperature")
              : t(`sensor_types:${getNameSlug(selectedSensor.Sensor_type.name)}`)
          }
        />
      ),
      [chartData, onSelectedRange, convertToHumidity, convertToTemperature, selectedSensor, chartHolderLayout?.width],
    );

    return (
      <StyledBlurWrapper className="note-mode-chart">
        <StyledContentWrapper direction="column" chartHolderLayout={chartHolderLayout}>
          <StyledChartHolder className="holder" chartHolderLayout={chartHolderLayout}>
            {chart}
          </StyledChartHolder>

          {step === "addNote" && (
            <>
              <Box className="divider" width="100%" />
              <TextArea
                name="Note"
                placeholder="Add note"
                initialValue={selectedNote?.content ?? ""}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                  setNote(event.target.value);
                }}
              />
            </>
          )}

          <NoteModeChartFooter
            setNoteMode={setNoteMode}
            selectedNote={selectedNote}
            setSelectedNote={setSelectedNote}
            selectedSensor={selectedSensor}
            step={step}
            setStep={setStep}
            startTime={startTime}
            endTime={endTime}
            note={note}
          />
        </StyledContentWrapper>
      </StyledBlurWrapper>
    );
  },
);

const StyledBlurWrapper = styled(Stack)(() => ({
  zIndex: 1500,
  position: "absolute",
  left: 0,
  top: 0,
  backdropFilter: "blur(5px)",
  width: "100vw",
  height: "100vh",
}));

const StyledContentWrapper = styled(Stack)(({ chartHolderLayout }: { chartHolderLayout?: IChartHolderLayout }) => ({
  position: "relative",
  top: "60%",
  transform: "translateY(-50%)",
  left: chartHolderLayout?.left,
  width: chartHolderLayout?.width,
}));

const StyledChartHolder = styled(Stack)(({ chartHolderLayout }: { chartHolderLayout?: IChartHolderLayout }) => ({
  height: chartHolderLayout?.height,
}));
