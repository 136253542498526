import React from "react";
import { useTranslation } from "react-i18next";
import { ISensorDataNote } from "../../../../Managers/Types";
import { getUserDateFormat } from "../../../../AppState";
import { getTimeFormatValueForUser } from "../../../../Enums/TimeFormat";
import { Stack } from "@mui/material";
import "./MobileNoteCards.scss";
import moment from "moment";

interface IMobileNoteCards {
  notes: Array<ISensorDataNote>;
  handleEditNote: (note: ISensorDataNote) => void;
  handleDelete: (note: ISensorDataNote) => void;
}

export const MobileNoteCards: React.FC<IMobileNoteCards> = ({ notes, handleEditNote, handleDelete }) => {
  const { t } = useTranslation(["device_detail", "common"]);

  const dateFormat = getUserDateFormat();
  const timeFormat = getTimeFormatValueForUser();

  return (
    <Stack className="note-cards" direction="column" gap="1em">
      {notes.map((note: ISensorDataNote) => (
        <Stack className="note-card" direction="row" justifyContent="space-between" padding="1em">
          <Stack direction="column" gap="0.5em">
            <span>
              <strong>Start time: </strong>
              {moment(note.startTime).format(`${dateFormat} ${timeFormat}`)}
            </span>

            <span>
              <strong>End time: </strong>
              {moment(note.endTime).format(`${dateFormat} ${timeFormat}`)}
            </span>
            <span>{note.content}</span>
          </Stack>

          <Stack direction="column" justifyContent="flex-start" gap="1em">
            <button className="btn btn-icon" onClick={() => handleEditNote(note)}>
              <i className="fa fa-edit button-icon" />
              <span className="sr-only">{t("common:edit")}</span>
            </button>

            <button className="btn btn-icon" onClick={() => handleDelete(note)}>
              <i className="fa fa-trash button-icon" />
              <span className="sr-only">{t("common:delete")}</span>
            </button>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};
