import moment from "moment";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import { getDeviceSensorData } from "../../../Managers";
import { measurementTransform } from "../../../Managers/MeasurementService";
import { addAcknowledgementMessage, updateNotification } from "../../../Managers/NotificationService";
import { isLocalWAM, prepareChartDataSet } from "../../../Managers";
import { AppState, showAppModal, showSnackbar } from "../../../AppState";
import { ILineChartValue, ISetPoint, PermissionBlocker, Stepper } from "../../../Components";
import { INotification } from "../../../Managers/Types";
import { useTranslation } from "react-i18next";
import "./AlertResolveModal.scss";
import { Modal } from "../../../Components/Modal";
import { NotesStep } from "./NotesStep";
import { GraphStep } from "./GraphStep";
import { AsideInfo } from "./AsideInfo";
import { LoginStep } from "./LoginStep";
import { ResolutionStep } from "./ResolutionStep";
import { PermissionEnum } from "../../../Enums";

interface IAlertResolveModalProps {
  notification: INotification;
  refetchNotifications: () => void;
}

// TODO: We need to figure out how this form is meant to be used. It has a bunch of inconsistencies noted below like incorrectly spelled
// fields that would have prevented it from working properly IMO.
export const AlertResolveModal: React.FC<IAlertResolveModalProps> = observer(
  ({ notification: initialNotification, refetchNotifications }) => {
    const [steps, setSteps] = useState<{ key: any; label: string }[]>([]);
    const [currentStep, setCurrentStep] = useState(0);
    const [isSaving, setIsSaving] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean | null>(null);
    const [alertResolutionNotes, setAlertResolutionNotes] = useState<string>("");
    const [identifier, setIdentifier] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [graphData, setGraphData] = useState<ILineChartValue[]>([]);
    const [setPoints, setSetPoints] = useState<ISetPoint[]>([]);
    const [notification, setNotification] = useState<INotification>(initialNotification);

    const { t } = useTranslation(["alert_resolve", "alerts"]);

    const _getSensorData = useCallback(() => {
      setGraphData([]);

      // TODO: The old code assumed Sensor would always be set but I saw records where it was null
      if (!notification.Sensor) {
        console.warn("Skipping getting sensor data for invalid notification", notification);
        setIsLoading(false);
        return;
      }

      setSetPoints([]);

      const createdAt = new Date(notification.createdAt);
      const endTime = moment(createdAt).endOf("day");
      const startTime = moment(createdAt).startOf("day");

      setIsLoading(true);
      getDeviceSensorData(notification.Sensor, startTime.toISOString(), endTime.toISOString(), false)
        .then((r) => {
          if (r && r.length) {
            // TODO: The old code referred to Sensor.Device directly but it didn't seem to always be set so I added a fallback
            let sensorData = prepareChartDataSet(r, notification.Sensor!, notification.Sensor?.is_imperial);
            //let sensorData = transformSensorData(r, notification.Sensor);

            setGraphData([...sensorData]);

            setSetPoints([
              {
                x: new Date(notification.createdAt),
                // TODO: The old code referred to Sensor.Device directly but it didn't seem to always be set so I added fallbacks
                // entry.value.value, [sensor.default_unit, isImperial, sensor.Sensor_type.type, disableRoundingRules]
                y: measurementTransform(notification.value.value, {
                  unit: notification.Sensor?.default_unit,
                  empirical: notification.Sensor?.is_imperial,
                  type: notification.Sensor?.Sensor_type.type,
                }),
                _id: 1,
              } as ISetPoint,
            ]);
          } else {
            setGraphData([]);
          }
        })
        .catch((e) => {
          const errorMessage = t("alert_resolve:sensor_data_fetch_error");
          showSnackbar(errorMessage, "error");
          console.log(errorMessage, e);
        })
        .finally(() => setIsLoading(false));
    }, [notification]);

    useEffect(() => {
      _getSensorData();
      const baseSteps = [
        { key: 1, label: t("alert_resolve:step_alert") },
        { key: 2, label: t("alert_resolve:step_response") },
        { key: 3, label: t("alert_resolve:step_resolution") },
      ];

      // if it is a shared user, it should have 4 steps
      if (isLocalWAM() && AppState.user?.role === "SHAREDUSER") {
        setSteps([...baseSteps, { key: 4, label: "" }]);
      } else {
        setSteps(baseSteps);
      }
    }, [_getSensorData]);

    const proceedAlert = () => {
      const nextStep = currentStep + 1;

      console.log(nextStep, steps);
      if (nextStep === steps.length) {
        setIsSaving("resolve");
        updateNotification(
          { ...notification, is_resolved: true, resolved_notes: alertResolutionNotes, UserId: AppState.user?._id || 0 },
          identifier,
          password,
        )
          .then((r) => {
            console.log("Update result", r);
            showAppModal(null);
            showSnackbar(t("alert_resolve:resolve_alert_success"), "success");
          })
          .catch((e) => {
            console.log("Update error", e);
            setIsSaving("");

            if (AppState.user?.role === "SHAREDUSER") {
              showSnackbar(t("alert_resolve:resolve_alert_error_shared_user"), "error");
            } else {
              showSnackbar(t("alert_resolve:resolve_alert_error"), "error");
            }
          });
      } else {
        setCurrentStep(nextStep);
      }
    };

    const goToAcknowledgeStep = async () => {
      try {
        await markAlertAcknowledged();
        setCurrentStep(1);
      } catch (error) {
        console.error("Failed to acknowledge alert:", error);
      }
    };

    const goToResolutionStep = async () => {
      await markAlertAcknowledged();
      setCurrentStep(2);
    };

    const markAlertAcknowledged = async () => {
      if (AppState?.user?._id && !notification.acknowledged_by_user_ids.includes(AppState?.user?._id)) {
        const updatedNotification = await addAcknowledgementMessage(notification);
        setNotification({
          ...notification,
          acknowledged_by_user_ids: updatedNotification.acknowledged_by_user_ids || [],
          acknowledgement_notes: updatedNotification.acknowledgement_notes || [],
        });
        refetchNotifications();
      }
    };

    const snoozeAlert = () => {
      setIsSaving("snooze");
      updateNotification({ ...notification, is_snoozed: true }, identifier, password)
        .then((r) => {
          console.log("Update result", r);
          showAppModal(null);
          showSnackbar(t("alert_resolve:snooze_alert_success"), "success");
        })
        .catch((e) => {
          console.log("Update error", e);
          setIsSaving("");
          showSnackbar(t("alert_resolve:snooze_alert_error"), "error");
        });
    };

    return (
      <Modal
        title={t("alert_resolve:alert")}
        className="alert-modal-main"
        bodyClassName="alert-modal-body custom-scrollbar"
        buttons={
          <>
            <button type="button" className="btn btn-info u-text-uppercase" onClick={() => showAppModal(null)}>
              {t("common:cancel")}
            </button>
            {currentStep === 0 && notification && !notification.is_snoozed ? (
              <button className="btn btn-info u-text-uppercase" onClick={() => snoozeAlert()}>
                {isSaving === "snooze" ? <i className="fa fa-circle-o-notch fa-spin" /> : <></>}
                {t("alert_resolve:snooze")}
              </button>
            ) : null}

            {currentStep < 1 ? (
              <button className="btn btn-info u-text-uppercase" onClick={() => goToAcknowledgeStep()}>
                {t("alert_resolve:acknowledge")}
              </button>
            ) : null}

            <PermissionBlocker permission={PermissionEnum.RESOLVE_ALERTS}>
              {currentStep < 2 ? (
                <button className="btn btn-primary u-text-uppercase" onClick={goToResolutionStep}>
                  {t("alert_resolve:go_to_resolution")}
                </button>
              ) : (
                <button
                  className="btn btn-primary u-text-uppercase"
                  onClick={proceedAlert}
                  disabled={currentStep === 3 && (!identifier || !password)}>
                  {isSaving === "resolve" ? <i className="fa fa-circle-o-notch fa-spin" /> : null}
                  {t("alert_resolve:resolve")}
                </button>
              )}
            </PermissionBlocker>
          </>
        }>
        <Stepper activeStep={currentStep} orientation="horizontal" steps={steps}>
          <AsideInfo notification={notification} />

          {notification.is_resolved ? (
            <div>
              <label className="input-label">{t("alert_resolve:notes")}: </label>
              <p>{notification.resolved_notes}</p>
            </div>
          ) : null}

          {currentStep === 0 ? (
            <GraphStep isLoading={!!isLoading} setPoints={setPoints} graphData={graphData} notification={notification} />
          ) : null}

          {currentStep === 1 ? (
            <NotesStep notification={notification} refetchNotifications={refetchNotifications} setNotification={setNotification} />
          ) : null}

          {currentStep === 2 ? (
            <ResolutionStep
              notification={notification}
              alertResolutionNotes={alertResolutionNotes}
              setAlertResolutionNotes={setAlertResolutionNotes}
              resolveAlert={proceedAlert}
            />
          ) : null}

          {currentStep === 3 ? (
            <LoginStep identifier={identifier} setIdentifier={setIdentifier} password={password} setPassword={setPassword} />
          ) : null}
        </Stepper>
      </Modal>
    );
  },
);
