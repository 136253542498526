import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { showAppModal, showSnackbar } from "../AppState";
import { Modal } from "../Components/Modal";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { DatepickerDropdown } from "../Components";
import moment from "moment/moment";
import { extendAccountDemoEndDate } from "../Managers/AccountsService";

interface IDemoEndDateExtendModalProps {
  header: string;
  confirmButtonType?: string;
  onCancel?: () => void;
  accountId?: number;
  demoEndDate?: string;
}

export const DemoEndDateExtendModal: React.FC<IDemoEndDateExtendModalProps> = observer(
  ({ header, confirmButtonType = "btn-primary", onCancel, accountId, demoEndDate }) => {
    const [endDate, setEndDate] = useState<Date>(demoEndDate ? new Date(demoEndDate) : moment().add(30, "days").toDate());
    const [changeDemoInProgress, setChangeDemoInProgress] = useState<boolean>(false);

    const { t } = useTranslation(["accounts", "common"]);

    const extendDemoEndDate = async () => {
      if (!accountId) {
        console.error("Account ID is undefined");
        return;
      }

      setChangeDemoInProgress(true);
      try {
        await extendAccountDemoEndDate(accountId, endDate)
          .then((r) => {
            showSnackbar(t("accounts:extend_demo_success"), "success");
            showAppModal(null);
          })
          .catch(() => {
            showSnackbar(t("accounts:extend_demo_error"), "error");
          });
      } catch (error) {
        console.error("An error occurred while extending demo end date:", error);
        showSnackbar(t("accounts:extend_demo_error"), "error");
      } finally {
        setChangeDemoInProgress(false);
      }
    };

    const handleSetEndDate = (date: Date) => {
      const today = moment().startOf("day").toDate();
      if (date >= today) {
        setEndDate(date);
      }
    };

    return (
      <Modal
        className="modal-sm"
        title={header}
        bodyClassName={"demo-end-date-modal"}
        buttons={
          <>
            <button
              disabled={changeDemoInProgress}
              className={classNames("btn", "btn-info")}
              onClick={() => {
                onCancel ? onCancel() : showAppModal(null);
              }}>
              {t("common:cancel")}
            </button>

            <button className={classNames("btn", confirmButtonType)} disabled={changeDemoInProgress} onClick={extendDemoEndDate}>
              {t("common:confirm")}
            </button>
          </>
        }>
        <div>
          <div className="demo-date-picker-wrapper">
            <label className="input-label">{t("accounts:new_demo_end_date")}</label>
            <DatepickerDropdown
              selectedDate={endDate}
              minDate={moment().startOf("day").toDate()}
              onSelectDate={(date) => handleSetEndDate(date)}
            />

            <p className={"demo-date-picker-description"}>
              <i className="fa fa-info-circle" />
              {t("accounts:new_demo_end_date_description")}
            </p>
          </div>

          {changeDemoInProgress ? <i className="fa fa-spin fa-spinner" /> : null}
        </div>
      </Modal>
    );
  },
);

export default DemoEndDateExtendModal;
