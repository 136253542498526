import React, { useRef } from "react";
import { CircularProgress } from "@mui/material";
import { ILineChartValue } from "../../../../Components";
import { ISensor, ISensorDataNote } from "../../../../Managers/Types";
import { NoteModeChart } from "./NoteModeChart";
import { useSensorDataNotes } from "../../../../Managers/API";
import { ZoomModeChart } from "./ZoomModeChart";
import { createPortal } from "react-dom";

interface IChartController {
  noteMode: boolean;
  setNoteMode: React.Dispatch<React.SetStateAction<boolean>>;
  selectedNote?: ISensorDataNote;
  setSelectedNote: React.Dispatch<React.SetStateAction<ISensorDataNote | undefined>>;
  isGraphLoading: boolean;
  chartData: ILineChartValue[];
  convertToHumidity: boolean;
  convertToTemperature: boolean;
  selectedSensor: ISensor;
}

export const ChartController: React.FC<IChartController> = ({
  noteMode,
  setNoteMode,
  selectedNote,
  setSelectedNote,
  isGraphLoading,
  chartData,
  convertToHumidity,
  convertToTemperature,
  selectedSensor,
}) => {
  const { data: notes, isFetching } = useSensorDataNotes(selectedSensor._id);
  const zoomChartRef = useRef<HTMLDivElement>(null);

  if (isGraphLoading || isFetching) {
    return (
      <div className="holder">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      {noteMode &&
        createPortal(
          <NoteModeChart
            ref={zoomChartRef}
            chartData={chartData}
            convertToHumidity={convertToHumidity}
            convertToTemperature={convertToTemperature}
            setNoteMode={setNoteMode}
            selectedNote={selectedNote}
            setSelectedNote={setSelectedNote}
            selectedSensor={selectedSensor}
          />,
          document.body,
        )}

      <div ref={zoomChartRef} className="holder">
        <ZoomModeChart
          chartData={chartData}
          convertToHumidity={convertToHumidity}
          convertToTemperature={convertToTemperature}
          selectedSensor={selectedSensor}
          notes={notes}
        />
      </div>
    </>
  );
};
