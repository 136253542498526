import React, { useMemo } from "react";
import { getNameSlug } from "../../../../Managers";
import { ILineChartValue, TimeSeriesLineChart } from "../../../../Components";
import { ISensor, ISensorDataNote } from "../../../../Managers/Types";
import { useTranslation } from "react-i18next";

interface IZoomModeChart {
  chartData: ILineChartValue[];
  convertToHumidity: boolean;
  convertToTemperature: boolean;
  selectedSensor: ISensor;
  notes: Array<ISensorDataNote>;
}

export const ZoomModeChart: React.FC<IZoomModeChart> = ({ chartData, convertToHumidity, convertToTemperature, selectedSensor, notes }) => {
  const { t } = useTranslation(["sensor_types"]);

  const selectedAreas = (notes ?? []).map((note: ISensorDataNote) => ({
    startTime: new Date(note.startTime),
    endTime: new Date(note.endTime),
  }));

  const chart = useMemo(
    () => (
      <TimeSeriesLineChart
        data={chartData}
        setPoints={[]}
        valueName={
          convertToHumidity
            ? t("sensor_types.humidity")
            : convertToTemperature
            ? t("sensor_types.temperature")
            : t(`sensor_types:${getNameSlug(selectedSensor.Sensor_type.name)}`)
        }
        selectedAreas={selectedAreas}
      />
    ),
    [selectedAreas, convertToHumidity, convertToTemperature, selectedSensor, chartData],
  );

  return <>{chart}</>;
};
