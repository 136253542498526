import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import "./DeviceDetailModal.scss";
import { OrderBy } from "../../../../Enums";
import { DeviceDetailModal } from "./DeviceDetailModal";
import { IDevice, ISensor } from "../../../../Managers/Types";

function getInitialTabIndex(sensors: ISensor[], sensorId?: number): number {
  if (sensorId == null) {
    return 0;
  }

  const tabIndex = sensors.findIndex((sensor) => sensor._id === sensorId);

  return tabIndex !== -1 ? tabIndex : 0;
}

function getSortedAndFilteredSensors(sensors: ISensor[]): ISensor[] {
  return (
    sensors
      .filter((sensor) => sensor.Sensor_type.name !== "Battery Voltage" && sensor.Sensor_type.name !== "Signal Strength")
      .sort((sensor1, sensor2) => {
        if (sensor1.Sensor_type.name === "Temperature") {
          return -1;
        } else if (sensor2.Sensor_type.name === "Temperature") {
          return 1;
        } else if (sensor1.Sensor_type.name === "Humidity") {
          return -1;
        } else if (sensor2.Sensor_type.name === "Humidity") {
          return 1;
        }
        return 0;
      }) || []
  );
}

interface IDeviceDetailModalSensorLayer {
  device: IDevice;
  sensorId?: number;
  refresh?: (order?: OrderBy, sort?: string) => void;
}

export const DeviceDetailModalSensorLayer: React.FC<IDeviceDetailModalSensorLayer> = observer(({ device, sensorId }) => {
  const sortedAndFilteredSensors = getSortedAndFilteredSensors(device.Sensors ?? []);

  const [sensorTabIndex, setSensorTabIndex] = useState(() => getInitialTabIndex(sortedAndFilteredSensors, sensorId));

  return (
    <DeviceDetailModal
      device={device}
      sensors={sortedAndFilteredSensors}
      sensorTabIndex={sensorTabIndex}
      setSensorTabIndex={setSensorTabIndex}
    />
  );
});
