import React from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "../../../../Components/Table";
import { useTranslation } from "react-i18next";
import { ISensorDataNote } from "../../../../Managers/Types";
import { getUserDateFormat } from "../../../../AppState";
import moment from "moment";
import { getTimeFormatValueForUser } from "../../../../Enums/TimeFormat";

interface INotesTable {
  notes: Array<ISensorDataNote>;
  handleEditNote: (note: ISensorDataNote) => void;
  handleDelete: (note: ISensorDataNote) => void;
}

export const NotesTable: React.FC<INotesTable> = ({ notes, handleEditNote, handleDelete }) => {
  const { t } = useTranslation(["device_detail", "common"]);

  const dateFormat = getUserDateFormat();
  const timeFormat = getTimeFormatValueForUser();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell width="25%">{t("device_detail:notes.start")}</TableCell>
          <TableCell width="25%">{t("device_detail:notes.end")}</TableCell>
          <TableCell width="40%">{t("device_detail:notes.note")}</TableCell>
          <TableCell width="5%" />
          <TableCell width="5%" />
        </TableRow>
      </TableHead>

      <TableBody>
        {notes.map((note) => (
          <TableRow key={note._id}>
            <TableCell>{moment(note.startTime).format(`${dateFormat} ${timeFormat}`)}</TableCell>
            <TableCell>{moment(note.endTime).format(`${dateFormat} ${timeFormat}`)}</TableCell>
            <TableCell>{note.content}</TableCell>
            <TableCell>
              <button className="btn btn-icon" onClick={() => handleEditNote(note)}>
                <i className="fa fa-edit button-icon" />
                <span className="sr-only">{t("common:edit")}</span>
              </button>
            </TableCell>
            <TableCell>
              <button className="btn btn-icon" onClick={() => handleDelete(note)}>
                <i className="fa fa-trash button-icon" />
                <span className="sr-only">{t("common:delete")}</span>
              </button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
