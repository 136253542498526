import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { StyledTooltip } from "../../Components";
import { calcBatteryStrength, getBatteryTooltipKey } from "../../Managers";
import classNames from "classnames";

interface IBatteryIconProps {
  battery: number;
}

export const BatteryIcon: React.FC<IBatteryIconProps> = observer(({ battery }) => {
  const { t } = useTranslation("dashboard");

  const battery_unknown = battery === 9999;
  const batteryPercentage = calcBatteryStrength(battery);
  const batteryTooltip = battery_unknown ? null : getBatteryTooltipKey(battery);

  return (
    <StyledTooltip title={batteryTooltip ? t(batteryTooltip) : null}>
      <i
        className={classNames(`icon-sprite icon-sprite-battery_${batteryPercentage}`, battery_unknown ? "icon-sprite-battery-unknown" : "")}
      />
    </StyledTooltip>
  );
});
