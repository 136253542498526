import { observer } from "mobx-react-lite";
import React from "react";
import { useDevice } from "../../../../Managers";
import "./DeviceDetailModal.scss";
import { OrderBy } from "../../../../Enums";
import { CircularProgress } from "@mui/material";
import { DeviceDetailModalSensorLayer } from "./DeviceDetailModalSensorLayer";

interface IDeviceDetailModalDeviceLayer {
  deviceId: number;
  sensorId?: number;
  refresh?: (order?: OrderBy, sort?: string) => void;
}

export const DeviceDetailModalDeviceLayer: React.FC<IDeviceDetailModalDeviceLayer> = observer(({ deviceId, sensorId, refresh }) => {
  const deviceQuery = useDevice(deviceId);
  const device = deviceQuery.data;

  if (!device) {
    return <CircularProgress />;
  }

  return <DeviceDetailModalSensorLayer device={device} sensorId={sensorId} refresh={refresh} />;
});
