import React from "react";
import { ISensor, ISensorDataNote } from "../../../../Managers/Types";
import { useTranslation } from "react-i18next";
import { Box, Stack } from "@mui/material";
import { showSnackbar } from "../../../../AppState";
import { postSensorDataNote, putSensorDataNote } from "../../../../Managers/API";
import { useQueryClient } from "react-query";
import "./NoteModeChart.scss";
import { INoteModeStep } from "./NoteModeChart";

interface INoteModeChartFooter {
  setNoteMode: React.Dispatch<React.SetStateAction<boolean>>;
  selectedNote?: ISensorDataNote;
  setSelectedNote: React.Dispatch<React.SetStateAction<ISensorDataNote | undefined>>;
  selectedSensor: ISensor;
  step: INoteModeStep;
  setStep: React.Dispatch<React.SetStateAction<INoteModeStep>>;
  startTime: Date | null;
  endTime: Date | null;
  note: string;
}

export const NoteModeChartFooter: React.FC<INoteModeChartFooter> = ({
  setNoteMode,
  selectedNote,
  setSelectedNote,
  selectedSensor,
  step,
  setStep,
  startTime,
  endTime,
  note,
}) => {
  const { t } = useTranslation(["device_detail", "common"]);
  const queryClient = useQueryClient();

  const footerInstruction =
    step === "selectRange"
      ? t("device_detail:note_mode.select_range_step.instruction")
      : t("device_detail:note_mode.add_note_step.instruction");

  const handleCancel = () => {
    setNoteMode(false);
    setSelectedNote(undefined);
  };

  const handleNextStep = () => {
    if (!startTime || !endTime) {
      showSnackbar(t("device_detail:note_mode.range_not_selected"), "warning");
      return;
    }

    setStep("addNote");
  };

  const handleSave = () => {
    if (!note) {
      showSnackbar(t("device_detail:note_mode.note_not_provided"), "warning");
      return;
    }

    const savePromise = selectedNote
      ? putSensorDataNote(selectedNote._id, {
          ...selectedNote,
          startTime: startTime!,
          endTime: endTime!,
          content: note,
        })
      : postSensorDataNote({
          _id: -1,
          startTime: startTime!,
          endTime: endTime!,
          content: note,
          SensorId: selectedSensor._id,
        });

    return savePromise
      .then(() => {
        setNoteMode(false);
        setSelectedNote(undefined);
        queryClient.invalidateQueries(["SENSOR_DATA_NOTES", selectedSensor._id]).then();
      })
      .catch(() => {
        showSnackbar(t("device_detail:note_mode.save_error"), "error");
      });
  };

  return (
    <Stack direction="row" className="note-mode-chart-footer" component="footer" padding="0.75em" justifyContent="space-between" gap="1em">
      <Box alignContent="center">{footerInstruction}</Box>

      <Stack direction="row" gap="1em">
        {step === "selectRange" && (
          <>
            <button className="btn btn-secondary" onClick={handleCancel}>
              {t("common:cancel")}
            </button>
            <button className="btn btn-primary" onClick={handleNextStep}>
              {t("common:next")}
            </button>
          </>
        )}
        {step === "addNote" && (
          <>
            <button className="btn btn-secondary" onClick={() => setStep("selectRange")}>
              {t("common:back")}
            </button>

            <button className="btn btn-primary" onClick={handleSave}>
              {t("common:save")}
            </button>
          </>
        )}
      </Stack>
    </Stack>
  );
};
