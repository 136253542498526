import { ILineChartValue, InputError, ISetPoint, SelectInput, TextInput, TimeSeriesLineChart } from "../../Components";
import { formatDateCustom, getConverts, getDeviceSensorData, getSensorType, prepareChartDataSet } from "../../Managers";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";
import { IDevice } from "../../Managers/Types";
import { getUserDateFormat, showAppModal, showSnackbar } from "../../AppState";
import { Table, TableBody, TableCell, TableHead, TableRow } from "../../Components/Table";
import { unitsTransform } from "../../Managers/UnitsService";
import "./PointOfInterestSetup.scss";
import { PointOfInterestTableModal } from "./PointOfInterestTableModal";
import { CircularProgress } from "@mui/material";
import { IChartDataProps } from "./DeviceReportModal";
import Tooltip from "@mui/material/Tooltip";
import { getTimeFormatValueForUser } from "../../Enums/TimeFormat";

interface ITargetPointTrigger {
  value?: string;
  dir: "above" | "below";
  noMatchingPoints?: boolean;
  error?: string;
}

export const PointsOfInterestSetup: React.FC<{
  minDate: Date;
  maxDate: Date;
  selectedSensors: IChartDataProps[];
  saveSelectedSensors: (sensors: IChartDataProps[]) => void;
}> = ({ minDate, maxDate, selectedSensors, saveSelectedSensors }) => {
  const [selectedDevice, setSelectedDevice] = useState<IDevice>(selectedSensors[0].sensor.Device!);
  const [selectedSensor, setSelectedSensor] = useState<IChartDataProps>(selectedSensors[0]);
  const [graphLoading, setGraphLoading] = useState(false);
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const [chartData, setChartData] = useState<ILineChartValue[]>([]);
  const [setPoints, setSetPoints] = useState<ISetPoint[]>([]);
  const [totalElapsedTime, setTotalElapsedTime] = useState("");
  const [setPointsError, setSetPointsError] = useState("");
  const [convertToRh, setConvertToRh] = useState(false);
  const [convertToTemp, setConvertToTemp] = useState(false);
  const [targetPoint, setTargetPoint] = useState<ITargetPointTrigger>({ dir: "above" });

  const { t } = useTranslation(["common", "export"]);

  const dateFormat = getUserDateFormat();
  const timeFormat = getTimeFormatValueForUser(true);

  useEffect(() => {
    if (!selectedSensor) {
      return;
    }

    console.log("Getting sensor data", { minDate, maxDate, selectedSensor });

    setGraphLoading(true);
    setSetPointsError("");

    getDeviceSensorData(selectedSensor.sensor, minDate.toISOString(), maxDate.toISOString(), true)
      .then((r) => {
        const processed = prepareChartDataSet(r, selectedSensor.sensor, selectedSensor.sensor.is_imperial, false, false);
        setChartData(processed);
        setInitialDataLoaded(true);
      })
      .catch((e) => {
        const errorMessage = t("export:load_error");
        showSnackbar(errorMessage, "error");
        console.log(errorMessage, e);
      })
      .finally(() => setGraphLoading(false));
  }, [minDate, maxDate, selectedSensor]);

  useEffect(() => {
    setInitialDataLoaded(false);
    const sensor = selectedSensors.find((s) => s.sensor._id === selectedSensor?.sensor._id);
    if (sensor) {
      setSetPoints(sensor.setPoints ?? []);
    }
  }, [selectedSensor]);

  const findSetPointForTarget = (target: ISetPoint) => _relocateSetPoints([...setPoints.slice(), target]);

  const deviceOptions = useCallback(() => {
    return selectedSensors.reduce((arr, sensor) => {
      if (sensor.sensor.Device && !arr.some((item: IDevice) => item._id === sensor.sensor.Device?._id)) {
        arr.push(sensor.sensor.Device);
      }
      return arr;
    }, [] as IDevice[]);
  }, [selectedSensors]);

  const sensorOptions = useCallback(() => {
    return selectedSensors.reduce((arr, sensor) => {
      if (
        !arr.some((item: IChartDataProps) => item.sensor._id === sensor.sensor._id) &&
        sensor.sensor.Device?._id === selectedDevice?._id
      ) {
        arr.push(sensor);
      }
      return arr;
    }, [] as IChartDataProps[]);
  }, [selectedDevice]);

  const _displayElapseTime = (millisecond: number) => {
    if (!millisecond || millisecond < 0) {
      return "00:00";
    }

    let hour, min, sec;
    let totalSecond = Math.round(Math.abs(millisecond) / 1000);

    if (totalSecond >= 3600) {
      hour = Math.floor(totalSecond / 3600);
      totalSecond = totalSecond % 3600;
    }

    min = Math.floor(totalSecond / 60);
    totalSecond = totalSecond % 60;
    sec = totalSecond;

    if (min < 10) {
      min = "0" + min;
    }

    if (sec < 10) {
      sec = "0" + sec;
    }

    return hour ? hour + ":" + min + ":" + sec : min + ":" + sec;
  };

  const clearPoints = () => {
    setSetPoints([]);
    setSetPointsError("");
  };

  useEffect(() => {
    if (selectedSensor?.sensor.Device) {
      const { convertToRh, convertToTemp } = getConverts(selectedSensor?.sensor.Device);
      setConvertToRh(convertToRh);
      setConvertToTemp(convertToTemp);
    }
  }, [selectedSensor]);

  const deleteNewPoint = (i: number) => {
    setSetPointsError("");
    const newSetPoints = setPoints.slice();
    newSetPoints.splice(i, 1);
    setSetPoints(newSetPoints);
    _relocateSetPoints(newSetPoints);
  };

  const _relocateSetPoints = (points: ISetPoint[]) => {
    const localSetPoints: ISetPoint[] = [];
    let targets = [...points.sort((p1, p2) => p1.x.valueOf() - p2.x.valueOf())];

    if (targets.length === 1 && points.length === 1) {
      targets[0].elapsed = "";
      setTotalElapsedTime("");
      setSetPoints(targets);
    }

    targets.forEach(({ _id, unit, ...rest }, i) => {
      // If it cannot find the new setPoint in the updated dataset
      localSetPoints.push({
        _id,
        ...rest,
        elapsed: i > 0 ? _displayElapseTime(moment(targets[targets.length - 1].x).diff(moment(localSetPoints[0].x))) : "",
        unit: unitsTransform(unit, [
          selectedSensor?.sensor.default_unit,
          selectedSensor?.sensor.is_imperial,
          selectedSensor?.sensor.Sensor_type.name,
          convertToRh,
          convertToTemp,
        ]),
      });
    });

    setSetPoints(localSetPoints);

    if (localSetPoints.length) {
      setTotalElapsedTime(_displayElapseTime(moment(localSetPoints[localSetPoints.length - 1].x).diff(moment(localSetPoints[0].x))));
    } else {
      setTotalElapsedTime("");
    }
  };

  const setFilteredData = (data: ILineChartValue[]) => {
    const sensor = selectedSensors.find((s) => s.sensor._id === selectedSensor?.sensor._id);
    if (sensor) {
      sensor.data = data;
    }
  };

  const saveSetPoints = () => {
    const modifiedSensors = selectedSensors.slice();
    const sensor = modifiedSensors.find((s) => s.sensor._id === selectedSensor?.sensor._id);

    if (sensor) {
      sensor.setPoints = setPoints;
      saveSelectedSensors(modifiedSensors);
      showSnackbar(t("export:save_selected_set_points_success"), "success");
    }
  };

  const handleTargetChange = () => {
    if (!targetPoint.value || isNaN(Number(targetPoint.value))) {
      setTargetPoint({ ...targetPoint, error: "123" });
      return;
    }

    const value = Number(targetPoint.value);

    const points: ISetPoint[] = [];
    const currentSetPoints = setPoints.slice();

    chartData.forEach((point, i) => {
      if (currentSetPoints.some((p) => p._id === i)) {
        return;
      }

      const triggeredTarget = typeof point.y === "string" ? Number(point.y) : point.y;
      if (targetPoint.dir === "above" && triggeredTarget && value && triggeredTarget >= value) {
        points.push({ _id: i, y: point.y, triggeredTarget, unit: point.unit, x: point.x });
      } else if (targetPoint.dir === "below" && triggeredTarget && value && triggeredTarget <= value) {
        points.push({ _id: i, y: point.y, triggeredTarget, unit: point.unit, x: point.x });
      }
    });

    setTargetPoint({ ...targetPoint, noMatchingPoints: !points.length });
    _relocateSetPoints([...currentSetPoints, ...points]);
  };

  return (
    <div id="step2">
      <div className="row">
        <div className="col-sm-3">
          <SelectInput
            style={{ marginTop: 8 }}
            label={t("common:device")}
            menuItemClass="dark"
            inputClassName="dark"
            value={selectedDevice}
            onChange={setSelectedDevice}
            displayEmpty={true}
            options={[
              ...deviceOptions().map((d: IDevice) => ({
                value: d,
                label: d.name,
              })),
            ]}
          />
          <SelectInput
            menuItemClass="dark"
            inputClassName="dark"
            value={selectedSensor}
            label={t("common:sensor")}
            onChange={setSelectedSensor}
            displayEmpty={true}
            options={[
              ...sensorOptions().map((s: IChartDataProps) => ({
                value: s,
                label: s.sensor.Sensor_type.name,
              })),
            ]}
          />
          <div>
            <p className="input-label">{t("export:serial_number")}:</p>
            <p>{selectedDevice?.serial_number ?? "-"}</p>
          </div>
          <div>
            <p className="input-label">{t("export:placement")}:</p>
            <p>{selectedDevice?.location_note ?? "-"}</p>
          </div>
        </div>

        <div className="col-sm-9">
          <div className="graph-holder">
            {selectedDevice && selectedSensor && !initialDataLoaded ? (
              <CircularProgress />
            ) : selectedDevice && selectedSensor ? (
              <TimeSeriesLineChart
                zoomEnabled={false}
                data={chartData}
                setPoints={setPoints}
                valueName={getSensorType(selectedDevice, t) || selectedSensor?.sensor.Sensor_type.name}
                setFilteredDataHook={setFilteredData}
                onSetPointClick={findSetPointForTarget}
                loading={graphLoading}
              />
            ) : null}
          </div>
        </div>
      </div>

      <br />
      {selectedSensor ? (
        <div className="row u-flex-center">
          <div className="col-sm-4">
            <TextInput
              onChange={(e) => setTargetPoint({ ...targetPoint, value: e.target.value, error: "" })}
              value={targetPoint.value?.toString() ?? ""}
              name="target-point"
            />
            <InputError error="123" displayError={!!targetPoint.error} touched={true} />
          </div>
          <SelectInput
            onChange={(dir) => setTargetPoint({ ...targetPoint, dir })}
            className="col-sm-4"
            value={targetPoint.dir}
            options={["above", "below"].map((dir) => ({
              label: t("export:direction", { context: dir }),
              value: dir,
            }))}
          />
          <div className="col-sm-1 u-text-error">
            {targetPoint.noMatchingPoints ? (
              <Tooltip className="size" title={t("export:no_matching_points")}>
                <i className="fa fa-warning" />
              </Tooltip>
            ) : undefined}
          </div>
          <div className="col-sm-3">
            <button className="btn btn-secondary pull-right" onClick={() => handleTargetChange()}>
              + {t("export:add_target_point")}
            </button>
          </div>
        </div>
      ) : null}

      <br />

      {selectedSensor ? (
        <div>
          <div className="u-mobile-hide">
            <span className="sr-only">{t("export:remove_all_targets")}</span>
            <button type="button" className="btn btn-plain" onClick={() => clearPoints()} disabled={!setPoints.length}>
              <i className="fa fa-trash" /> {t("export:clear_all_targets")}
            </button>
          </div>

          <div className="export-modal-table-wrapper">
            <Table className="points-of-interest-table">
              <TableHead>
                <TableRow>
                  <TableCell>{t("export:target_point")}</TableCell>
                  <TableCell>{t("export:reading")}</TableCell>
                  <TableCell>{t("export:date_time")}</TableCell>
                  <TableCell>{t("export:elapsed_time")}</TableCell>
                  <TableCell>{t("export:remove")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {setPoints.map((setPoint, i) => (
                  <TableRow key={setPoint._id}>
                    <TableCell>
                      {setPoint.triggeredTarget}
                      {unitsTransform(selectedSensor.sensor.default_unit, [
                        selectedSensor?.sensor.default_unit,
                        selectedSensor?.sensor?.is_imperial,
                        selectedSensor?.sensor?.Sensor_type?.name,
                        convertToRh,
                        convertToTemp,
                      ])}
                    </TableCell>
                    <TableCell>{setPoint.triggeredTarget}</TableCell>
                    <TableCell>{setPoint.x ? formatDateCustom(setPoint.x, `${dateFormat} ${timeFormat}`) : "--"}</TableCell>
                    <TableCell>{setPoint.elapsed || "--:--"}</TableCell>
                    <TableCell>
                      <button type="button" className="btn btn-plain" onClick={() => deleteNewPoint(i)}>
                        <i className="fa fa-times-circle" />
                        <span className="sr-only">{t("export:remove_target")}</span>
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          <div className="row u-mobile-only"></div>

          {setPoints.length ? (
            <div className="set-point-action-row">
              <div>
                <span className="u-desktop-hide sr-only">{t("export:remove_all_targets")}</span>
                <button
                  type="button"
                  className="btn btn-plain u-text-teal u-desktop-hide"
                  onClick={() => clearPoints()}
                  disabled={!setPoints.length}>
                  <i className="fa fa-trash u-text-teal" /> {t("export:clear_all_targets")}
                </button>
              </div>
              <div className="pull-right u-text-blue">
                {t("export:total_elapsed_time")}: {totalElapsedTime || "--:--"}
              </div>
            </div>
          ) : null}

          {!setPoints.length ? (
            <>
              <p className="u-text-small u-opacity-fade">{t("export:point_click_info_first_line")}</p>
              <p className="u-text-small u-opacity-fade">{t("export:point_click_info_second_line")}</p>
            </>
          ) : (
            <div className="set-point-action-row set-point-action-buttons">
              <button className="btn btn-primary" onClick={() => saveSetPoints()}>
                {t("export:add_to_exports")}
              </button>
              <button
                onClick={() => {
                  showAppModal(<PointOfInterestTableModal sensors={selectedSensors} />);
                }}
                className="btn btn-plain u-text-teal">
                <i className="fa fa-eye u-mobile-only" /> {t("export:see_list")}
              </button>
            </div>
          )}

          <p className="u-text-error">{setPointsError}</p>
        </div>
      ) : null}
    </div>
  );
};
