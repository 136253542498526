import { action, observable } from "mobx";
import {
  IAlert,
  IDevice,
  IDeviceGroup,
  IGateway,
  ILocation,
  ILocationDetail,
  ISystemMessage,
  ISnackbarMessage,
  IStoredValue,
  IUser,
  SnackbarType,
} from "./Managers/Types";
import { Endpoint, getLocationDetail, queryClient, setAuthToken } from "./Managers/API";
import { getSensorTypes } from "./Managers/AlertService";
import { ISensorType } from "./Managers/Alert.model";
import { IAccount } from "./Views/Accounts/Types";
import { getMe } from "./Managers/UsersService";
import { changeLanguage } from "i18next";
import { getAllSystemMessages } from "./Managers/ManageSystemMessageService";
import { DateFormat, WindowSize } from "./Enums";
import { DEFAULT_LANGUAGE } from "./i18n";
import { TimeFormat } from "./Enums/TimeFormat";

const LAST_VISITED_KEY = "lastVisited";

export const AppState = observable({
  // Whether the user is authenticated. Should be used wherever an if/then is needed to display auth'd-only controls (as opposed to
  // checking myUser for null).
  isAuthenticated: false,

  //pagination for device
  devicePage: 0,
  deviceLimit: 10,
  // The user's profile
  user: null as IUser | null,

  // We store the "selected" location and device/group here bceause so much is wired to it across the app. But we want to get away from
  // storing the others to make it easier to update other lists and objects dynamimcally
  selectedLocation: null as ILocationDetail | null,
  fetchedDevices: [] as IDevice[],

  selectedGateway: null as IGateway | null,
  selectedAllDevices: false,
  selectedDeviceGroup: null as IDeviceGroup | null,
  allDevicesGateway: null as IGateway | null,

  // These are the post conversion ones. We changed to an ID isntead of tracking the whole record because it makes auto updates easier.
  // Each controller that cares about an item "finds" the item in the API-call-returned list (users, alerts, etc) using this ID. This
  // helps them reload data as mutations happen. React-query will notify the listening controllers of new data, but if the controllers
  // make copies like they did in the old system the copies wouldn't be updated. This way everything is automatic.
  //
  // In the future it would be good to get away from this entirely. Controllers can track their own selections in local state, they
  // don't need to stuff this into the store. They were only doing it to "pass" the values off to modals, and we have an easier way
  // to do that now.
  selectedUserId: 0,
  selectedLocationId: 0,
  selectedLocationGatewayCount: 0, // We need the gateway count in a few spots. The old code pulled in the whole array but this is simpler.
  selectedAlertConfigId: 0,
  selectedGatewayId: 0,
  selectedDeviceIds: [] as number[],

  selectedAccount: null as IAccount | null,
  systemMessages: [] as ISystemMessage[],

  // From old code
  selectedDevices: [] as IDevice[],

  sensorTypes: [] as ISensorType[],

  appModal: null as JSX.Element | null,
  chipModal: null as JSX.Element | null,
  chipTemplate: null as JSX.Element | null,
  chipCount: 0,
  chipModalConfig: null as any,
  appModalConfig: null as any,
  snackBarMessage: { message: "" } as ISnackbarMessage,

  mode: WindowSize.DESKTOP as WindowSize.DESKTOP | WindowSize.TABLET | WindowSize.MOBILE,
});

const TOKEN_KEY = (process.env.JWT_KEY = "retain_token");

// This is here rather than in API.ts to avoid a circular reference between the two
// The old code base had this comment. Is this sufficient? Or do we need a nicer message?
//    TODO: enable system wide notification for errors and alerts
Endpoint.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log("Handling API error", error);
    console.log(JSON.stringify(error));
    if (error.response?.status === 401 && error.response?.data === "jwt expired") {
      console.log("[APPSTATE] JWT expired, clearing user session");
      setLoggedIn(false, null, null);
      window.location.href = "/";
    }
    return Promise.reject(error);
  },
);

// Not exported because we only really plan for this to be called post-login or post-load of a cached session. Before calling this,
// AppState.user should be set. Note that this is not marked async / returns nothing because the expectation is it will run in
// parallel to other startup tasks, so it should not be awaited.
const postLoginTasks = () => {
  console.log("[APPSTATE] Executing post-login tasks");

  if (!AppState.user) {
    return;
  }

  if (AppState.user.language !== DEFAULT_LANGUAGE) {
    changeLanguage(AppState.user.language);
  }

  getSensorTypes().then(
    action((sensorTypes) => {
      // console.log(`[APPSTATE] Loaded ${sensorTypes.length} sensor types`);
      AppState.sensorTypes = sensorTypes;
    }),
  );

  fetchSystemMessagesData();

  const lastVisited = window.localStorage.getItem(LAST_VISITED_KEY);
  const lastLocation = lastVisited ? AppState.user?.Locations.find((loc) => loc._id === +lastVisited) : null;
  if (lastLocation) {
    // console.log("[APPSTATE] Pre-selecting saved location", lastVisited);
    setSelectedLocation(lastLocation);
  } else if (AppState.user.Locations?.length > 0) {
    // console.log("[APPSTATE] Pre-selecting first known location", AppState.user.Locations[0]._id);
    setSelectedLocation(AppState.user.Locations[0]);
  }
};

// TODO: users/me returns different (less) data than login.
export const setLoggedIn = action((isAuthenticated: boolean, token: string | null, user: IUser | null) => {
  AppState.isAuthenticated = isAuthenticated;
  setAuthToken(token);
  AppState.user = user;
  token ? localStorage.setItem(TOKEN_KEY, token) : localStorage.removeItem(TOKEN_KEY);

  if (user) {
    postLoginTasks();
  } else {
    queryClient.removeQueries();
    clearDeclinedCookies();
  }
});

export const clearDeclinedCookies = () => {
  localStorage.removeItem("hasDeclinedCookies");
};

export const fetchSystemMessagesData = action(() => {
  getAllSystemMessages().then(
    action((systemMessagesData) => {
      // console.log("[APPSTATE] Loaded system messages data ", systemMessagesData);
      if (Array.isArray(systemMessagesData)) {
        AppState.systemMessages = [];
        systemMessagesData.forEach((message) => {
          AppState.systemMessages.push(message);
        });

        // console.log(AppState.systemMessages);
      }
    }),
  );
});

export const checkSession = action(() => {
  const token = localStorage.getItem(TOKEN_KEY);
  if (token) {
    // console.log("[APPSTATE] Found session token, checking validity");
    setAuthToken(token);
    return getMe()
      .then((user) => {
        // console.log("[APPSTATE] Loaded my profile", user);
        AppState.user = user;
        AppState.isAuthenticated = true;
        postLoginTasks();
        return true;
      })
      .catch((e) => {
        // console.warn("[APPSTATE] Unable to get my profile", e);
        setAuthToken(null);
        localStorage.removeItem(TOKEN_KEY);
        return false;
      });
  } else {
    // console.log("[APPSTATE] No session token found");
    clearDeclinedCookies();
    return Promise.resolve(false);
  }
});

export const refreshAppState = action(() => {
  return getMe()
    .then((user) => {
      // console.log("[APPSTATE] User's profile refreshed: ", user);
      AppState.user = user;
    })
    .catch((e) => {
      // console.warn("[APPSTATE] Unable to refresh user profile", e);
    });
});

export const setFetchedDevices = action((devices: Array<IDevice>) => {
  AppState.fetchedDevices = devices;
});

export const setSelectedLocation = action((location: ILocation | ILocationDetail, refreshView = true) => {
  if (refreshView) {
    AppState.selectedGateway = null;
    AppState.selectedDeviceGroup = null;
    AppState.devicePage = 0;
    AppState.selectedLocationId = -1;
    deselectAllDevices();
  }

  // Some entry paths give us different location data from the server depending on whether it came from login or query responses.
  // We use the location above to set everything up, but then we call the server for details to make sure we have the full-depth record.
  if (location) {
    getLocationDetail(location._id, AppState.devicePage)
      .then(
        action((location) => {
          AppState.selectedLocationId = location?._id || 0;
          AppState.selectedLocationGatewayCount = location?.Gateways.length || 0;
          AppState.selectedLocation = location;
          if (location) {
            window.localStorage.setItem(LAST_VISITED_KEY, "" + location._id);
          }
        }),
      )
      .catch((e) => console.warn("[APPSTATE] Unable to get location detail", e));
  }
});

export const refreshLocationData = () => {
  const lastVisited = window.localStorage.getItem(LAST_VISITED_KEY);
  const lastLocation = lastVisited ? AppState.user?.Locations.find((loc) => loc._id === +lastVisited) : null;
  if (lastLocation) {
    // console.log("[APPSTATE] Refreshed location", lastVisited);
    setSelectedLocation(lastLocation);
  }
};

export const setDevices = (devices: IDevice[]) => {
  devices.forEach((d) => {
    setDevice(d);
  });
};

export const setStateDefaultAlertValues = (values?: IStoredValue[]) => {
  if (!values || !AppState.user) return;
  AppState.user.defaultAlertValues = values;
};

export const refreshFavoriteLocation = (location: ILocation) => {
  if (!location || !AppState.user) return;

  AppState.user.Locations = AppState.user.Locations.map((loc) =>
    loc._id === location._id ? { ...loc, is_favorite: !loc.is_favorite } : loc,
  );
};

export const setDevice = action((device: IDevice) => {
  if (AppState.selectedLocation?.Gateways) {
    const gateway = AppState.selectedLocation?.Gateways.find((x) => x.Devices?.rows.find((d) => d._id === device._id)?._id === device._id);
    if (AppState.fetchedDevices)
      if (gateway) {
        const gatewayIndex = AppState.selectedLocation.Gateways.findIndex((x) => x._id === gateway._id);
        const deviceInGatewayIndex = gateway.Devices.rows.findIndex((x) => x._id === device._id);

        if (deviceInGatewayIndex >= 0 && gateway.Devices) {
          const existingDevice = gateway.Devices.rows[deviceInGatewayIndex];

          if (device.Sensors) {
            gateway.Devices.rows[deviceInGatewayIndex] = device;
            AppState.fetchedDevices[deviceInGatewayIndex] = device;
          } else {
            gateway.Devices.rows[deviceInGatewayIndex] = { ...existingDevice, ...device };
            AppState.fetchedDevices[deviceInGatewayIndex] = { ...existingDevice, ...device };
          }

          AppState.selectedLocation.Gateways[gatewayIndex] = gateway;
        }
      }
  }
  deselectAllDevices();
});

export const setDeviceGroup = action((deviceGroup: IDeviceGroup) => {
  if (AppState.selectedLocation) {
    const allDeviceGroupIds = AppState.selectedLocation.Device_groups.map((x) => x._id);

    // handle upsert
    if (allDeviceGroupIds.includes(deviceGroup._id)) {
      const deviceGroupToUpdateIndex = AppState.selectedLocation.Device_groups.findIndex((x) => x._id === deviceGroup._id);

      AppState.selectedLocation.Device_groups[deviceGroupToUpdateIndex] = deviceGroup;
    } else {
      deviceGroup.Devices = [];
      AppState.selectedLocation.Device_groups.push(deviceGroup);
    }
  }
});

export const setDeviceGroupDevices = action((deviceGroupId: number | null, devices: IDevice[]) => {
  if (AppState.selectedLocation) {
    const allDevices = getDevices();
    if (deviceGroupId) {
      const i = AppState.selectedLocation.Device_groups.findIndex((d) => d._id === deviceGroupId);
      const existingDeviceGroupDevices = AppState.selectedLocation.Device_groups[i].Devices;
      AppState.selectedLocation.Device_groups[i].Devices = [...existingDeviceGroupDevices, ...devices];

      devices.forEach((d) => {
        const device = allDevices.find((x) => x._id === d._id);
        if (device) {
          device.DeviceGroupId = deviceGroupId;
          setDevice(device);
        }
      });
    } else {
      devices.forEach((d) => {
        const device = allDevices.find((x) => x._id === d._id);
        if (device) {
          device.DeviceGroupId = 0;

          setDevice(device);
        }
      });
    }
  }
});

export const setSelectedDeviceGroup = action((deviceGroup: IDeviceGroup | null) => {
  AppState.selectedDeviceGroup = deviceGroup;
  AppState.selectedAllDevices = false;

  if (deviceGroup) {
    AppState.selectedGateway = null;
  }
});

export const setGlobalSelectedGateway = action((gateway: IGateway | null) => {
  if (gateway) {
    AppState.selectedDeviceGroup = null;
  }

  AppState.selectedAllDevices = false;
  AppState.selectedGateway = gateway;
  AppState.selectedGatewayId = gateway?._id || 0;
  if (gateway) {
    const index = AppState.selectedLocation?.Gateways.findIndex((g) => g._id === gateway?._id);
    if (index !== undefined && AppState.selectedLocation) {
      AppState.selectedLocation.Gateways[index] = gateway;
    }
  }
});

export const setSelectedAllDevices = action((selected: boolean) => {
  AppState.selectedAllDevices = selected;
  AppState.selectedDeviceGroup = null;
  AppState.selectedGateway = null;
});

export const setSelectedDevices = action((devices: any[]) => {
  AppState.selectedDevices = devices;
  AppState.selectedDeviceIds = devices.map((device) => device._id);
});

export const selectDevice = action((device: IDevice) => {
  deselectAllDevices();
  AppState.selectedDevices.push(device);
  AppState.selectedDeviceIds.push(device._id);
});

export const setSelectedUserId = action((id: number | null | undefined) => {
  AppState.selectedUserId = id || 0;
});

export const setSelectedAlertConfigId = action((id: number | null | undefined) => {
  AppState.selectedAlertConfigId = id || 0;
});

export const deselectDevice = action((device: IDevice) => {
  AppState.selectedDevices = AppState.selectedDevices.filter((entry) => entry._id !== device._id);
  AppState.selectedDeviceIds = AppState.selectedDeviceIds.filter((entry) => entry !== device._id);
});

export const deselectAllDevices = action(() => {
  AppState.selectedDevices = [];
  AppState.selectedDeviceIds = [];
});

export const showAppModal = action((modal: JSX.Element | null, config: any = null) => {
  AppState.appModal = modal;
  AppState.appModalConfig = config;
});

export const setAppModalConfig = action((config: any = null) => {
  AppState.appModalConfig = config;
});

export const showChipModal = action((modal: JSX.Element | null, config: any = null) => {
  AppState.chipModal = modal;
  AppState.chipModalConfig = config;
});
export const setChipModalConfig = action((config: any = null) => {
  AppState.chipModalConfig = config;
});

export const showSnackbar = action((message: string, type: SnackbarType) => {
  AppState.snackBarMessage = { message, type };
});

export const setSelectedAccount = action((account: IAccount | null) => {
  AppState.selectedAccount = account;
});

export const setChipTemplate = action((element: JSX.Element | null) => {
  AppState.chipTemplate = element;
});
export const setChipCount = action((count: number) => {
  AppState.chipCount = count;
});

export const getSelectedDevices = () => {
  const alerts = [] as IAlert[];
  const devices = [] as IDevice[];

  const selectedDeviceIds = AppState.selectedDevices.map((x) => x._id);
  const gateways = AppState.selectedLocation?.Gateways;

  if (gateways) {
    AppState.fetchedDevices.forEach((device) => {
      const deviceIds = devices.map((x) => x._id);
      if (selectedDeviceIds.includes(device._id) && !deviceIds.includes(device._id)) {
        devices.push(device);

        alerts.push(...getDeviceAlerts(device));
      }
    });
  }

  return { devices, alerts };
};

export const getDeviceAlerts = (device: IDevice) => {
  const alerts: IAlert[] = [];
  device.Sensors.forEach((sensor) => {
    sensor.Alerts.forEach((alert) => {
      const sensorAlert = alert.Sensor_alerts;
      if (sensorAlert) {
        alerts.push(alert);
      }
    });
  });

  return alerts;
};

export const getGateway = (gatewayId: number | null) => {
  const gateways = AppState.selectedLocation?.Gateways;

  if (gateways && gatewayId != null) {
    return gateways.find((x) => x._id === gatewayId);
  }

  return null;
};

export const deduplicate = (arr: IDevice[]): IDevice[] => {
  return arr?.filter((thing, index, self) => index === self.findIndex((t) => t._id === thing?._id));
};

export const getDevices = (gatewayId: number | null = null) => {
  let devices: IDevice[] = [];

  if (AppState.selectedLocation) {
    if (gatewayId === null) {
      AppState.selectedLocation.Gateways.filter((el) => el.Devices.count).forEach((g) => {
        devices = [...devices, ...g.Devices.rows];
      });
      return devices;
    } else {
      const gateway = AppState.selectedLocation.Gateways.find((g) => g._id === gatewayId);

      if (gateway) return deduplicate(gateway.Devices.rows);
    }
  }

  return [] as IDevice[];
};

export const getDeviceGroups = () => {
  if (AppState.selectedLocation) {
    return AppState.selectedLocation.Device_groups;
  }

  return [] as IDeviceGroup[];
};

export const getCurrencySign = () => {
  const currency = AppState.user?.Account?.Currency;
  if (currency) {
    return currency.sign;
  }

  return "$";
};

export const getUserDateFormat = () => AppState.user?.date_format ?? DateFormat.MMDDYYYY;

export const getUserTimeFormat = () => AppState.user?.time_format ?? TimeFormat.TWELVE_HOUR;
