import React from "react";
import { ILineChartValue, ISetPoint, TimeSeriesLineChart } from "../../../Components";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { INotification } from "../../../Managers/Types";

interface IGraphStep {
  graphData: ILineChartValue[];
  setPoints: ISetPoint[];
  isLoading: boolean;
  notification: INotification;
}

export const GraphStep: React.FC<IGraphStep> = ({ graphData, setPoints, isLoading, notification }) => {
  const { t } = useTranslation(["alert_resolve"]);

  if (isLoading) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  if (!graphData.length) {
    return <div />;
  }

  return (
    <div>
      <label className="input-label">{t("alert_resolve:alert_graph")} </label>
      <br />
      <TimeSeriesLineChart data={graphData} setPoints={setPoints} holderClassName={"chart-gradient-background"} height={250} />
      <br />
      <label className="input-label" htmlFor="protocol-info">
        {t("alert_resolve:alert_protocol")}
      </label>
      <textarea name="protocol-info" rows={4} className=" input-textarea disabled input">
        {notification?.Alert?.protocol || "No protocol provided."}
      </textarea>
      <p>{t("alert_resolve:acknowledging-info")}</p>
    </div>
  );
};
