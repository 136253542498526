import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { StyledTooltip } from "../../Components";
import { calcSignalStrength, getSignalTooltipKey } from "../../Managers";
import classNames from "classnames";
import { Box, Stack, styled } from "@mui/material";

interface ISignalIconProps {
  signalStrength: number;
}

export const SignalIcon: React.FC<ISignalIconProps> = observer(({ signalStrength }) => {
  const { t } = useTranslation("dashboard");

  // 418s don't have signal strength, so send over 9999
  const signal_unknown = signalStrength === 9999;
  const signalPercentage = calcSignalStrength(signalStrength);
  const signalTooltip = signal_unknown ? null : getSignalTooltipKey(signalStrength);

  return (
    <StyledTooltip title={signalTooltip ? t(signalTooltip) : null}>
      <Stack direction="row" style={{ position: "relative" }}>
        <Box
          component="i"
          margin="4px"
          className={classNames(`icon-sprite icon-sprite-signal_${signalPercentage}`, signal_unknown ? "icon-sprite-signal-unknown" : "")}
        />
        {signalPercentage === 0 && <StyledCross />}
      </Stack>
    </StyledTooltip>
  );
});

export const StyledCross = styled(Box)(({ theme }) => ({
  position: "absolute",
  left: 0,
  top: "50%",
  height: "1.5px",
  width: "100%",
  rotate: "-45deg",
  backgroundColor: theme.palette.background.paper,
}));
