import React, { useEffect, useState } from "react";
import { formatDateCustom } from "../Managers";
import { stringToDate } from "../Managers/AccountsService";
import { getUserDateFormat, showAppModal } from "../AppState";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { isUserRoleAllowed, UserRoles } from "../Enums";
import DemoEndDateExtendModal from "../Modals/DemoEndDateExtendModal";

export interface IDemoTimeRemaining {
  days: number;
  hours: number;
  minutes: number;
}

export interface IDemoRemainingDatesSectionProps {
  demoStartDate?: string;
  demoEndDate?: string;
  accountId?: number;
}

const TimeDisplay: React.FC<{ label: string; timeRemaining: IDemoTimeRemaining | null }> = ({ label, timeRemaining }) => {
  const { t } = useTranslation(["accounts", "common"]);

  if (!timeRemaining) return null;

  return (
    <div className="row main-panel-row full-width">
      <div className="col-xs-12">
        <p className="input-label">{label}</p>
        <p className="type-large-regular">
          {t("common:time_count", {
            days_count: timeRemaining.days,
            days_plural: t("common:days_long", { count: timeRemaining.days }),
            hours_count: timeRemaining.hours,
            hours_plural: t("common:hours_long", { count: timeRemaining.hours }),
            minutes_count: timeRemaining.minutes,
            minutes_plural: t("common:minutes_long", { count: timeRemaining.minutes }),
            remaining: t("common:remaining"),
          })}
        </p>
      </div>
    </div>
  );
};

export const DemoRemainingDatesSection: React.FC<IDemoRemainingDatesSectionProps> = ({ demoStartDate, demoEndDate, accountId }) => {
  const { t } = useTranslation(["accounts", "common"]);

  const [demoTimeRemaining, setDemoTimeRemaining] = useState<IDemoTimeRemaining | null>(null);
  const [demoNotStarted, setDemoNotStarted] = useState<IDemoTimeRemaining | null>(null);

  useEffect(() => {
    if (demoStartDate && demoEndDate) {
      const currentDate = moment();
      const startDate = moment(demoStartDate);
      const endDate = moment(demoEndDate);

      if (currentDate.isBefore(startDate)) {
        const diff = startDate.diff(currentDate);
        const duration = moment.duration(diff);
        const daysRemaining = Math.floor(duration.asDays());
        const hoursRemaining = duration.hours();
        const minutesRemaining = duration.minutes();
        setDemoNotStarted({ days: daysRemaining, hours: hoursRemaining, minutes: minutesRemaining });
        setDemoTimeRemaining(null);
      } else if (currentDate.isBefore(endDate)) {
        const diff = endDate.diff(currentDate);
        const duration = moment.duration(diff);
        const daysRemaining = Math.floor(duration.asDays());
        const hoursRemaining = duration.hours();
        const minutesRemaining = duration.minutes();
        setDemoTimeRemaining({ days: daysRemaining, hours: hoursRemaining, minutes: minutesRemaining });
        setDemoNotStarted(null);
      } else {
        // Demo period has ended
        setDemoTimeRemaining(null);
        setDemoNotStarted(null);
      }
    } else {
      setDemoTimeRemaining(null);
      setDemoNotStarted(null);
    }
  }, [demoStartDate, demoEndDate]);

  const showDemoEndDateExtendModal = () => {
    showAppModal(<DemoEndDateExtendModal header={t("accounts:extend_demo_end_date")} accountId={accountId} demoEndDate={demoEndDate} />);
  };

  return (
    <>
      {demoStartDate !== null && demoEndDate !== null && (
        <div style={{ marginTop: "2rem" }}>
          <div className="row main-panel-row full-width">
            <div className="col-xs-6">
              <p className="input-label">{t("accounts:demo_start_date")}</p>
              <p className="type-large-regular">{formatDateCustom(stringToDate(demoStartDate), getUserDateFormat())}</p>
            </div>

            <div className="col-xs-6">
              <p className="input-label">{t("accounts:demo_end_date")}</p>
              <p className="type-large-regular demo-end-date-wrapper">
                {formatDateCustom(stringToDate(demoEndDate), getUserDateFormat())}
                {isUserRoleAllowed(UserRoles.APP_ADMIN) && (
                  <i className="fa fa-edit u-text-teal demo-extend-icon" onClick={() => showDemoEndDateExtendModal()} />
                )}
              </p>
            </div>
          </div>

          <TimeDisplay label={t("accounts:demo_time_remaining")} timeRemaining={demoTimeRemaining} />
          <TimeDisplay label={t("accounts:demo_starts_in")} timeRemaining={demoNotStarted} />
        </div>
      )}
    </>
  );
};

export default DemoRemainingDatesSection;
