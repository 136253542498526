import { AppState } from "../AppState";
import { UserRoles } from "./UserRoles";

export const PermissionEnum = {
  // Admin permissions
  BILLING_ACCESS: "billingAccess",
  SUBSCRIPTION_ACCESS: "subscriptionAccess",
  EDIT_LOCATION: "editLocation",
  EDIT_USERS: "editUsers",
  REMOVE_DEVICES: "removeDevices",
  EDIT_ALERTS: "editAlerts",
  RESOLVE_ALERTS: "resolveAlerts",

  // Users permissions
  UPDATE_FIRMWARE: "updateFirmware",
  UPDATE_DEVICE_SETTINGS: "editDeviceSettings",
  DEVICE_GROUPS_MANAGEMENT: "editDeviceGroup",
};

export const isUserPermissionAllowed = (requiredPermission: string | string[]): boolean => {
  const isAppAdmin = AppState.user?.role === UserRoles.APP_ADMIN;

  if (isAppAdmin) {
    return true;
  }

  const userPermissions = AppState.user?.Permissions.map((p) => p.name);

  if (!userPermissions) {
    return false;
  }

  if (Array.isArray(requiredPermission)) {
    return userPermissions.some((p) => requiredPermission.some((requiredPermission) => requiredPermission === p));
  } else {
    return userPermissions.some((p) => p === requiredPermission);
  }
};
