import React from "react";
import { Box, Button, Stack } from "@mui/material";
import { StyledTooltip } from "../../../../Components";
import { useTranslation } from "react-i18next";
import { ISensorDataNote } from "../../../../Managers/Types";
import { NotesTableController } from "./NotesTableController";

interface INotesSection {
  handleAddNote: () => void;
  handleEditNote: (note: ISensorDataNote) => void;
  sensorId: number;
  returnToDeviceDetailModal: () => void;
}

export const NotesSection: React.FC<INotesSection> = ({ handleAddNote, handleEditNote, sensorId, returnToDeviceDetailModal }) => {
  const { t } = useTranslation("device_detail");

  return (
    <Stack component="section" direction="column" paddingTop="1em" gap="1em">
      <Stack component="header" direction="row" justifyContent="space-between">
        <StyledTooltip title={t("device_detail:notes.tooltip")}>
          <Box alignContent="center">
            <span className="u-color-light-blue">{t("device_detail:notes.notes")}</span>
            <i className="fa fa-info-circle u-color-light-blue" />
          </Box>
        </StyledTooltip>

        <Button onClick={handleAddNote}>
          <i className="fa fa-plus-circle u-text-teal" />
          {t("device_detail:notes.add_note")}
        </Button>
      </Stack>

      <NotesTableController handleEditNote={handleEditNote} sensorId={sensorId} returnToDeviceDetailModal={returnToDeviceDetailModal} />
    </Stack>
  );
};
