import { getUserTimeFormat } from "../AppState";

export enum TimeFormat {
  TWENTY_FOUR_HOUR = "24-hour",
  TWELVE_HOUR = "12-hour",
}

export const getTimeFormatValueForUser = (withSeconds = false) => {
  if (withSeconds) {
    return getUserTimeFormat() === TimeFormat.TWENTY_FOUR_HOUR ? "HH:mm:ss" : "hh:mm:ss a";
  }
  return getUserTimeFormat() === TimeFormat.TWENTY_FOUR_HOUR ? "HH:mm" : "hh:mm a";
};

export const UserTimeFormats: TimeFormat[] = Object.values(TimeFormat);
