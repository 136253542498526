import React, { useState } from "react";
import { IStandardInput } from "./Types";

export const TextArea: React.FC<IStandardInput> = ({
  name,
  placeholder = "",
  required = false,
  readOnly = false,
  disabled,
  onChange,
  initialValue = "",
}) => {
  const [value, setValue] = useState(initialValue);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
    onChange && onChange(e);
  };

  return (
    <textarea
      id={name}
      disabled={disabled}
      required={required}
      readOnly={readOnly}
      value={value}
      style={{ backgroundColor: "#042b4a" }}
      onChange={handleInputChange}
      className={"input input-line"}
      placeholder={placeholder}
      name={name}
      data-lpignore="true"
      rows={6}
    />
  );
};
