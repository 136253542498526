import { IDevice, ISensor } from "../Types";
import { getNotificationsHistory, parseAcknowledgementNotes } from "../NotificationService";
import moment from "moment/moment";
import * as Measurements from "../MeasurementService";
import { measurementTransform } from "../MeasurementService";
import * as Units from "../UnitsService";
import { unitsTransform } from "../UnitsService";
import { alertConditionTransform } from "../AlertConditionService";
import { TFunction } from "i18next";
import { AppState, getUserDateFormat, showAppModal } from "../../AppState";
import { checkForWai418Devices, checkForWai418HumidityDevice, checkForWai418TemperatureDevice, formatDateCustom } from "../Utils";
import { ILineChartValue, ISetPoint } from "../../Components";
import { isGenericAlert } from "../AlertService";
import { getIntervalOptions, ISensorDatapoint } from "../DeviceService";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { exportToXLSX } from "../FileFormatter";
import { IReportExportOptions } from "../../Modals/DeviceReport/ReportExport";
import { IReportOptions } from "../../Modals/DeviceReport/ReportOptions";
import { IChartDataProps } from "../../Modals/DeviceReport/DeviceReportModal";
import { getTimeFormatValueForUser } from "../../Enums/TimeFormat";
import { includeSensorDataNotesInPDF, includeSensorDataNotesInXLSX } from "./SensorDataNotesUtils";

export const prepareHistoricalData = async (
  sensorParams: IChartDataProps[],
  startDate: Date,
  endDate: Date,
  t: TFunction,
  includeNotes: boolean,
  includeProtocol: boolean,
  includeAcknowledgementNotes: boolean,
) => {
  const historicalData = await getNotificationsHistory(
    moment(startDate).startOf("day").toISOString(),
    moment(endDate).endOf("day").toISOString(),
  );

  const timeFormat = getTimeFormatValueForUser();
  const dateFormat = getUserDateFormat();

  const headers = [
    { header: t("export:value"), dataKey: "value" },
    { header: t("export:name"), dataKey: "name" },
    { header: t("export:condition"), dataKey: "condition" },
    { header: t("export:placement"), dataKey: "placement" },
    { header: t("export:serial"), dataKey: "serial" },
    { header: t("export:last_updated"), dataKey: "lastUpdateAt" },
    { header: t("export:resolved"), dataKey: "resolved" },
  ];

  if (includeNotes) {
    headers.push({ header: t("export:alert_notes"), dataKey: "notes" });
  }

  if (includeNotes) {
    headers.push({ header: t("export:alert_protocol"), dataKey: "protocol" });
  }

  if (includeAcknowledgementNotes) {
    headers.push({ header: t("export:alert_acknowledgement_notes"), dataKey: "acknowledgement" });
  }

  const parsedHistoricalData = historicalData
    .filter((alert) => sensorParams.some((item) => item.sensor._id === alert.Sensor?._id))
    .map((alert) => {
      let value =
        String(
          measurementTransform(alert.value.value, {
            unit: alert.Sensor?.default_unit || "degF",
            empirical: alert.Sensor?.is_imperial,
            type: alert.Sensor?.Sensor_type.type,
          }),
        ) +
        unitsTransform(alert.Sensor?.default_unit || "degF", [
          alert.Sensor?.default_unit || "degF",
          alert.Sensor?.is_imperial,
          alert.Sensor?.Sensor_type.type,
        ]);

      let alertName = "";

      if (alert.Alert) {
        alertName = alertConditionTransform(alert.Alert);

        if (!isGenericAlert(alert.Alert)) {
          alertName = alert.Alert.name + " - " + alertName;
        }
      }

      const alertCondition = alert.Alert ? alertConditionTransform(alert.Alert) : "";
      const placement = alert.Sensor?.Device?.location_note || "--";
      const serialNumber = alert.Sensor?.Device?.serial_number || "";
      const lastUpdateAt = moment(alert.updatedAt).format(`${dateFormat} ${timeFormat}`);
      const isResolved = alert.is_resolved ? t("common:yes") : t("common:no");
      const notes = includeNotes ? alert.resolved_notes : "";
      const protocol = includeProtocol ? alert.Alert.protocol : "";
      const acknowledgementNotes = includeAcknowledgementNotes ? parseAcknowledgementNotes(alert.acknowledgement_notes, true) : undefined;

      const params = [value, alertName, alertCondition, placement, serialNumber, lastUpdateAt, isResolved];

      if (includeNotes) {
        params.push(notes ?? "");
      }
      if (includeProtocol) {
        params.push(protocol ?? "");
      }

      if (includeAcknowledgementNotes) {
        if (acknowledgementNotes !== undefined) {
          params.push(acknowledgementNotes.join("\n"));
        } else {
          params.push("");
        }
      }

      return params;
    });

  return { historicalHeaders: headers, historicalData: parsedHistoricalData };
};

export const getSensorType = (device: IDevice, t: TFunction) => {
  const { convertToRh, convertToTemp } = getConverts(device);
  if (convertToTemp) {
    return t("export:temperature");
  } else if (convertToRh) {
    return t("export:humidity");
  }
  return null;
};

export const getConverts = (device: IDevice) => {
  const isWai418 = checkForWai418Devices(device.serial_number);

  if (isWai418 && device?.serial_number) {
    if (checkForWai418TemperatureDevice(device.serial_number)) {
      return { convertToTemp: true, convertToRh: false };
    } else if (checkForWai418HumidityDevice(device.serial_number)) {
      return { convertToTemp: false, convertToRh: true };
    } else {
      return { convertToTemp: false, convertToRh: false };
    }
  }
  return { convertToTemp: false, convertToRh: false };
};

export const renderHeaderFooterPDF = (doc: any, device: IDevice, t: TFunction, lotCode?: string, productCode?: string) => {
  doc.setFontSize(14);
  doc.setTextColor(0, 0, 0);
  // TODO: The old system did repeat this field 3x
  doc.text(AppState.selectedLocation?.name || "", 40, 50);
  doc.text(AppState.selectedLocation?.name || "", 40, 50);
  doc.text(AppState.selectedLocation?.name || "", 40, 50);
  doc.setFontSize(8);

  let subtitle = [];
  let address = AppState.selectedLocation?.address !== "please enter an address" ? AppState.selectedLocation?.address : null;
  let deviceLocation =
    address &&
    AppState.selectedLocation?.city &&
    AppState.selectedLocation?.state &&
    AppState.selectedLocation?.country &&
    AppState.selectedLocation?.zip
      ? address +
        ", " +
        AppState.selectedLocation?.city +
        ", " +
        AppState.selectedLocation?.state +
        ", " +
        AppState.selectedLocation?.country +
        " " +
        AppState.selectedLocation?.zip
      : null;
  if (deviceLocation) {
    subtitle.push(deviceLocation);
  }
  let devicePhone = AppState.selectedLocation?.phone !== "please enter a phone number" ? AppState.selectedLocation?.phone : null;
  if (devicePhone) {
    subtitle.push(devicePhone);
  }
  doc.text(subtitle.join(" | "), 40, 63);

  doc.setDrawColor(240, 240, 240);
  doc.setLineWidth(2);
  doc.line(40, 68, 550, 68);
  doc.setLineWidth(0.01);
  doc.line(40, 95, 550, 95);

  //devices
  doc.setFontSize(7);
  doc.setTextColor(180, 180, 180);
  doc.text(t("export:device_name"), 40, 78);
  doc.text(t("export:product_code"), 210, 78);
  doc.text(t("export:serial_number"), 300, 78);
  doc.text(t("export:lot_code"), 400, 78);

  doc.setFontSize(9);
  doc.setTextColor(100, 100, 100);
  doc.text(device.name || "n/a", 40, 90);
  doc.text(productCode ?? "n/a", 210, 90);
  doc.text(device.serial_number || "n/a", 300, 90);
  doc.text(lotCode ?? "n/a", 400, 90);

  doc.text(formatDateCustom(new Date(), getUserDateFormat()), doc.internal.pageSize.width - 100, 40);

  //footer
  doc.setFontSize(12);
  doc.setTextColor(160, 160, 160);
  doc.text(t("export:wireless_monitoring"), 40, doc.internal.pageSize.height - 50);
  doc.setFontSize(9);
  doc.setTextColor(200, 200, 200);
  doc.text(t("export:footer").replace("<--URL-->", window.origin), 40, doc.internal.pageSize.height - 40);
};

export const processInterval = (
  data: ILineChartValue[],
  interval: number,
  defaultIntervalOptionValue: number,
  possibleIntervalStart: Date,
) => {
  let dataToFilter = data.slice();

  if (interval !== defaultIntervalOptionValue && dataToFilter.length) {
    let startTime = Math.round((data[0]?.x.valueOf() ?? possibleIntervalStart.valueOf()) / 1_000);
    const reducedData: ILineChartValue[] = [];
    let i = 0;

    do {
      const item = dataToFilter.find((d) => Math.round(d.x.valueOf() / 1_000) === startTime + i * interval);
      if (item) {
        reducedData.push(item);
      } else {
        reducedData.push({
          x: new Date((startTime + i * interval) * 1000),
          y: "-",
          unit: dataToFilter[0]?.unit,
          triggeredTarget: null,
        });
      }
      i++;
    } while (startTime + i * interval <= Math.round(dataToFilter[dataToFilter.length - 1].x.valueOf() / 1_000));

    dataToFilter = reducedData;
  }

  return dataToFilter;
};

export const readingUOMString = (device: IDevice, sensor: ISensor) => {
  const { convertToTemp, convertToRh } = getConverts(device);

  return sensor
    ? " (" +
        unitsTransform(sensor?.default_unit, [
          sensor?.default_unit,
          sensor?.is_imperial,
          sensor?.Sensor_type.type,
          convertToRh,
          convertToTemp,
        ]) +
        ")"
    : "";
};

// Transform a dataset that may be "missing" some data values to be more "chartable". We do the following:
//
//  1. Unit conversion based on device/sensor settings
//
//  2. Simplify the data set to simple x,y values for charting
//
//  3. Sort the set just in case the server doesn't do this for us. It's supposed to, but we would break on the next
//     step if that ever failed so we do it here anyway to be safe.
//
//  4. We're supposed to show "gaps" where there's missing data, instead of connecting lines across missed data. The
//     server doesn't give us nulls for those missed items, which the chart needs. We insert placeholders, BUT...
//
//  5. The server also doesn't tell us a reporting interval for a device. We need to identify and insert nulls for the
//     chart to show gaps but we have to know where. So.. we guess. We find the shortest possible gap between two items
//     and assume that's the reporting interval. We then insert nulls at that interval in between items to create those
//     gaps. We insert as many nulls as we can because the chart is zoomable and it would be weird if you zoomed across
//     a big gap if we didn't.
//
// {_id: '60', SensorId: 2873, value: { value: 99.55 }, createdAt: '2022-03-02T08:05:00.000Z'}
// {_id: '61', SensorId: 2873, value: { value: 99.54 }, createdAt: '2022-03-02T08:15:00.000Z'}
// {_id: '62', SensorId: 2873, value: { value: 99.56 }, createdAt: '2022-03-02T08:20:00.000Z'}
// {_id: '63', SensorId: 2873, value: { value: 99.55 }, createdAt: '2022-03-02T08:25:00.000Z'}
//
// TODO: If we wanted to add a test suite this would be a great first thing to add tests for.
//
// NOTE: There are some more efficient approaches e.g. with array.splice() that could eliminate the second sort, that
// sort of thing. But before you waste tie here... I did a timer pass on a month's worth of data thrown in here and
// this took 8.6ms to process 8124 data points. It takes <1ms to process a more reasonable "month's worth" of data. So...
//

export const prepareChartDataSet = (
  data: ISensorDatapoint[],
  sensor: ISensor,
  isImperial: boolean = true,
  convertToHumidityForWai418: boolean = false,
  convertToTempForWai418: boolean = false,
): ILineChartValue[] => {
  const unit = Units.transform(sensor.default_unit, [
    sensor.default_unit,
    isImperial,
    sensor.Sensor_type.type,
    convertToHumidityForWai418,
    convertToTempForWai418,
  ]);
  const disableRoundingRules = false;
  let simplifiedSet: ILineChartValue[] = data.map((entry) => ({
    triggeredTarget: null,
    unit,
    x: new Date(entry.createdAt),
    y: !!entry.value
      ? Measurements.transform(entry.value.value, {
          unit: sensor.default_unit,
          empirical: isImperial,
          type: sensor.Sensor_type.type,
          disableRounding: disableRoundingRules,
          convertToHumidity: convertToHumidityForWai418,
          convertToTemperature: convertToTempForWai418,
        })
      : null,
    color: entry.color,
    shape: entry.shape,
  }));
  simplifiedSet.sort((a, b) => (a.x as Date).getTime() - (b.x as Date).getTime());
  // console.log('pcd, set=', simplifiedSet);

  // Time to get all old school. Hey, I had a 5-hr timebox for all this plus the charting...
  // let smallestGap = Infinity;
  // for (let i = 0; i < simplifiedSet.length - 1; i++) {
  //   const curr = simplifiedSet[i];
  //   const next = simplifiedSet[i + 1];
  //   const diff = (next.x as Date).getTime() - (curr.x as Date).getTime();
  //   if (diff < smallestGap) {
  //     // console.log('New smallest', curr.x, next.x);
  //     smallestGap = diff;
  //   }
  // }
  // // console.log('pcd, gap=', smallestGap);

  // // Sanity check to prevent us from trying to ever draw 500,000 (it happened) data points on a chart because we had 1-second reporting
  // // intervals. Gap-filling to 1 minute does enough without getting too crazy.
  // if (smallestGap < 60000) {
  //   smallestGap = 60000;
  // }

  // // I could have gone crazy with splice() but it would have been super hard to debug and array iteration and sorting in
  // // JS is actually really fast. Don't forget a simple for() loop with a splice-in-place would have had to modify its
  // // increment to account for the new items, and it gets weird stepping through that...
  // const entriesToAdd: ILineChartValue[] = [];
  // for (let i = 0; i < simplifiedSet.length - 1; i++) {
  //   const curr = simplifiedSet[i];
  //   const next = simplifiedSet[i + 1];
  //   let diff = (next.x as Date).getTime() - (curr.x as Date).getTime();

  //   let offset = smallestGap;
  //   while (diff > smallestGap) {
  //     entriesToAdd.push({ x: new Date((curr.x as Date).getTime() + offset), y: null, unit });
  //     diff -= smallestGap;
  //     offset += smallestGap;
  //   }
  // }

  // One last hurrah...
  // simplifiedSet = [...simplifiedSet, ...entriesToAdd];
  simplifiedSet.sort((a, b) => (a.x as Date).getTime() - (b.x as Date).getTime());
  return simplifiedSet;
};

export const saveReports = async (
  values: IReportExportOptions,
  sensors: IChartDataProps[],
  startDate: Date,
  endDate: Date,
  t: TFunction,
  imageDimensions: { height: number; width: number },
  options: IReportOptions,
  images?: Record<string, string>,
) => {
  const { fileFormat, interval } = values;
  const contentArray: Record<string, string | number | null | undefined>[] = [];
  let doc = new jsPDF("p", "pt");
  const isPdf = () => fileFormat === "pdf" || !fileFormat;

  const dataByDevice: Record<number, IChartDataProps[]> = {};

  sensors.forEach(({ data, sensor, setPoints }) => {
    if (!sensor.Device) {
      return;
    }

    const processedData = interval ? processInterval(data ?? [], interval, getIntervalOptions(t)[0].value, startDate) : data;

    if (dataByDevice[sensor.Device._id]) {
      dataByDevice[sensor.Device._id].push({ data: processedData, sensor, setPoints });
    } else {
      dataByDevice[sensor.Device._id] = [{ data: processedData, sensor, setPoints }];
    }
  });

  const dataByDeviceEntries = Object.entries(dataByDevice);

  for (let i = 0; i < dataByDeviceEntries.length; i++) {
    let historical: (string | number | null)[][] = [];
    let xlsxSensorDataNotes: (string | number | null)[][] = [];

    const [deviceId, data] = dataByDeviceEntries[i];
    const deviceImages: string[] = [];

    if (isPdf() && options.include_graph && images) {
      data.forEach((item) => {
        if (images[item.sensor._id]) {
          deviceImages.push(images[item.sensor._id]);
        }
      });
    }

    if (!isPdf() && options.include_alert_history) {
      const { historicalHeaders, historicalData } = await prepareHistoricalData(
        sensors,
        startDate,
        endDate,
        t,
        !!options.include_alert_notes,
        !!options.include_alert_protocol,
        !!options.include_alert_acknowledgement_notes,
      );
      historical.push([t("export:historical")]);
      historical.push(historicalHeaders.map((header) => header.header));
      historical.push(...historicalData);
    }

    if (isPdf() && options.include_alert_history) {
      const { historicalHeaders, historicalData } = await prepareHistoricalData(
        sensors,
        startDate,
        endDate,
        t,
        !!options.include_alert_notes,
        !!options.include_alert_protocol,
        !!options.include_alert_acknowledgement_notes,
      );

      if (historicalData.length) {
        doc.addPage("a4", "landscape");
        doc.setFontSize(18);
        doc.text(t("export:historical"), 40, 40);
        autoTable(doc, {
          head: [historicalHeaders.map((h) => h.header)],
          body: historicalData,
          startY: 70,
          margin: {
            top: 60,
            bottom: 70,
            left: 35,
          },
        });
      }
    }

    if (!isPdf() && options.include_sensor_data_notes) {
      await includeSensorDataNotesInXLSX(Number(deviceId), sensors, t, xlsxSensorDataNotes);
    }

    const device = sensors.find((sensor) => sensor.sensor.Device?._id === Number(deviceId))!.sensor.Device!;
    const content = await generateReportForSensors(
      values,
      device,
      data,
      doc,
      deviceImages,
      historical,
      xlsxSensorDataNotes,
      t,
      imageDimensions,
    );

    if (isPdf() && options.include_sensor_data_notes) {
      await includeSensorDataNotesInPDF(doc, Number(deviceId), sensors, t);
    }

    if (isPdf() && i !== dataByDeviceEntries.length - 1) {
      doc.addPage("a4", "portrait");
    } else if (content && Array.isArray(content)) {
      contentArray.push(...content, { [content.length]: "\n" }, { [content.length + 1]: "\n" });
    }
  }

  if (isPdf()) {
    doc.save((values.filename || t("common:untitled")) + ".pdf");
  } else {
    exportToXLSX(values.filename || t("common:untitled"), contentArray);
  }

  showAppModal(null);
};

const generateReportForSensors = async (
  values: IReportExportOptions,
  device: IDevice,
  sensorData: {
    sensor: ISensor;
    setPoints?: ISetPoint[];
    data?: ILineChartValue[];
  }[],
  doc: jsPDF,
  images: string[],
  historicalData: (string | number | null)[][] = [],
  sensorDataNotesData: (string | number | null)[][] = [],
  t: TFunction,
  imageDimensions: { height: number; width: number },
) => {
  const { fileFormat, productCode, lotCode } = values;
  const columns = [t("export:date_time"), ...sensorData.map((d) => d.sensor.display_name + readingUOMString(device, d.sensor))];

  const dataByDate: Record<string, Record<number, string | number | null>> = {};
  const dateWithTimeFormat = getUserDateFormat() + " " + getTimeFormatValueForUser();

  sensorData
    .filter((data) => !data.setPoints?.length)
    .forEach((sData) =>
      sData.data
        ?.filter((datapoint) => datapoint.y !== null)
        .forEach((datapoint) => {
          const key = moment(datapoint.x).format(dateWithTimeFormat);
          if (dataByDate[key]) {
            dataByDate[key][sData.sensor._id] = datapoint.y;
          } else {
            dataByDate[key] = { [sData.sensor._id]: datapoint.y };
          }
        }),
    );

  const overallData: Array<Array<string | number | null>> = Object.entries(dataByDate)
    .sort((a, b) => {
      if (moment(a[0]).isBefore(moment(b[0]))) {
        return -1;
      }
      return 1;
    })
    .map(([date, value]) => {
      const values: (string | number | null)[] = [moment(date).format(dateWithTimeFormat)];

      sensorData.forEach(({ sensor }) => {
        if (value[sensor._id] !== null && value[sensor._id] !== undefined) {
          values.push(value[sensor._id]);
        } else {
          values.push(null);
        }
      });

      return values;
    });

  const setPointDataEntries: { columns: string[]; data: (string | number | null)[][] }[] = [];

  sensorData
    .filter((data) => data.setPoints?.length)
    .forEach((sData) => {
      let lastReading: Date;
      let total = 0;

      const data = sData.setPoints!.map((reading) => {
        let duration = 0;

        if (lastReading) {
          // * this used to be reading.updatedAt
          duration = (reading.x || new Date()).getTime() - lastReading.getTime();
          total += duration;
        }
        // * this used to be reading.updatedAt
        lastReading = reading.x;

        return [
          reading.y ?? reading.triggeredTarget,
          reading.y,
          // * this used to be reading.updatedAt
          moment(reading.x).format(dateWithTimeFormat),
          duration !== 0 ? formatDuration(duration) : "n/a",
        ];
      });
      data.push([]);
      data.push(["", "", t("export:total_elapsed_time"), formatDuration(total)]);
      setPointDataEntries.push({
        columns: [
          t("export:target_point"),
          t("export:reading") + readingUOMString(device, sData.sensor),
          t("export:date_time"),
          t("export:elapsed_time"),
        ],
        data,
      });
    });

  if (fileFormat === "pdf" || !fileFormat) {
    if (images) {
      for (const imageData of images) {
        const index = images.indexOf(imageData);
        const img = new Image();
        img.src = imageData;

        doc.setFontSize(9);
        doc.addImage({
          imageData: img,
          format: "PNG",
          x: 20,
          y: index * 250 + 120,
          width: imageDimensions.width,
          height: imageDimensions.height,
        });
      }
    }

    if (overallData.length) {
      autoTable(doc, {
        head: [columns],
        body: overallData,
        startY: images.length ? images.length * 250 + 150 : 130,
        margin: {
          top: 100,
          bottom: 70,
          left: 35,
        },
        showFoot: "everyPage",
        didDrawPage: () => renderHeaderFooterPDF(doc, device, t, lotCode, productCode),
      });
    }

    setPointDataEntries.forEach((item, index) => {
      let startY = 130;

      if (index !== 0 || overallData.length) {
        doc.addPage();
      }

      if (images.length && index === 0 && !overallData.length) {
        startY = (images.length + 1) * 150 + 50;
      }

      autoTable(doc, {
        head: [item.columns],
        body: item.data,
        startY,
        showFoot: "everyPage",
        margin: {
          top: 100,
          bottom: 70,
          left: 35,
        },
        didDrawPage: () => renderHeaderFooterPDF(doc, device, t, lotCode, productCode),
      });
    });

    return doc;
  } else {
    // Add information about device;
    overallData.unshift(columns);
    overallData.unshift([t("export:serial_number"), device.serial_number]);
    overallData.unshift([t("export:device_name"), device.name]);
    overallData.unshift([t("report_modal:lot_code"), values.lotCode ?? ""]);
    overallData.unshift([t("report_modal:product_code"), values.productCode ?? ""]);
    overallData.unshift([t("export:device_location"), device.location_note || ""]);
    overallData.unshift([]);

    const setPointData: (string | number | null)[][] = [];

    setPointDataEntries.forEach((entry) => {
      setPointData.push(entry.columns);
      setPointData.push(...entry.data);
      overallData.unshift([]);
    });

    const combinedData = [...overallData, ...setPointData, ...historicalData, ...sensorDataNotesData];

    return combinedData.map((d) =>
      d.reduce((obj, item, index) => {
        obj[index] = item;
        return obj;
      }, {} as Record<string, number | string | null | undefined>),
    );
  }
};
const formatTime = function (num: number) {
  if (num < 10) {
    return "0" + num;
  } else {
    return num;
  }
};

function formatDuration(ms: number) {
  const s1 = Math.floor(ms / 1000);
  const s2 = s1 % 60;
  const m1 = Math.floor(s1 / 60);
  const m2 = m1 % 60;
  const h1 = Math.floor(m1 / 60);
  return formatTime(h1) + "h " + formatTime(m2) + "m " + formatTime(s2) + "s";
}
