import classNames from "classnames";
import { CheckboxInput, ICON_TOOLTIP_DEFAULT_DELAY, StyledTooltip } from "../../Components";
import moment from "moment";
import { measurementTransform } from "../../Managers/MeasurementService";
import React from "react";
import { useTranslation } from "react-i18next";
import { ISensor, IWatchlist } from "../../Managers/Types";
import { removeSensorFromWatchlist } from "../../Managers/WatchlistsService";
import { getUserDateFormat, showSnackbar } from "../../AppState";
import { isImperialUnit, unitsTransform } from "../../Managers/UnitsService";
import { observer } from "mobx-react-lite";
import { getTimeFormatValueForUser } from "../../Enums/TimeFormat";

interface IWatchlistTable {
  isSaving: string;
  setIsSaving: React.Dispatch<React.SetStateAction<string>>;
  refreshWatchlistsView: (watchlist: IWatchlist) => Promise<void>;
  sortedWatchlists: IWatchlist[];
  selectedWatchlist: IWatchlist;
  visibleSensors: Set<number>;
  setVisibleSensors: React.Dispatch<React.SetStateAction<Set<number>>>;
  atLeastOneSensorAvailable: boolean;
}

export const WatchlistTable: React.FC<IWatchlistTable> = observer(
  ({
    isSaving,
    setIsSaving,
    refreshWatchlistsView,
    sortedWatchlists,
    selectedWatchlist,
    visibleSensors,
    setVisibleSensors,
    atLeastOneSensorAvailable,
  }) => {
    const { t } = useTranslation(["watchlists"]);

    const dateFormat = getUserDateFormat();
    const timeFormat = getTimeFormatValueForUser();

    const toggleSensorVisibility = (sensorId: number) => {
      setVisibleSensors((prev) => {
        const newVisibleSensors = new Set(prev);
        if (newVisibleSensors.has(sensorId)) {
          newVisibleSensors.delete(sensorId);
        } else {
          newVisibleSensors.add(sensorId);
        }
        return newVisibleSensors;
      });
    };

    const refreshList = () => {
      let watchlist = sortedWatchlists.find((cfg) => cfg._id === selectedWatchlist._id);
      if (watchlist) {
        refreshWatchlistsView(watchlist);
      }
    };

    const displaySensorReadingWithUnit = (sensor: ISensor, isImperial: boolean | undefined = undefined) => {
      if (sensor.Sensor_type.type === "BOOLEAN") {
        return "";
      }

      const properIsImperial = isImperial !== undefined ? isImperial : sensor.is_imperial;

      return sensor.display_unit !== "" && sensor.default_unit !== sensor.display_unit
        ? sensor.display_unit
        : unitsTransform(sensor.default_unit, [sensor.default_unit, properIsImperial, sensor.Sensor_type.type]);
    };

    const deleteSensorFromWatchlist = (sensor: ISensor) => {
      setIsSaving("sensor" + sensor._id);
      removeSensorFromWatchlist(sensor, selectedWatchlist?._id || 0)
        .then((r) => {
          console.log("deleted", r);
          showSnackbar(t("watchlists:list.sensor_removal_success"), "success");
          refreshList();
        })
        .catch((e) => {
          console.log("delete error", e);
          showSnackbar(t("watchlists:list.sensor_removal_error", { name: sensor.display_name }), "error");
        })
        .finally(() => setIsSaving(""));
    };

    return (
      <>
        <div className="row u-desktop-only">
          <div className="col-sm-12">
            <ul className="select-group">
              <li className="select-group-item header">
                <div className="row u-full-width">
                  <div className="col-xs-1">{t("watchlists:table.headers_show")}</div>
                  <div className="col-xs-1 legends-header">{t("watchlists:table.headers_legend")}</div>
                  <div className="col-xs-4">{t("watchlists:table.headers_device_name")}</div>
                  <div className="col-xs-2">{t("watchlists:table.headers_last_updated")}</div>
                  <div className="col-xs-1">{t("watchlists:table.headers_value")}</div>
                  <div className="col-xs-2">{t("watchlists:table.headers_location")}</div>
                  <div className="col-xs-1">{t("watchlists:table.headers_remove")}</div>
                </div>
              </li>

              {atLeastOneSensorAvailable && selectedWatchlist.WatchlistSensors ? (
                selectedWatchlist.WatchlistSensors.map((watchlistSensor) => (
                  <li
                    key={watchlistSensor.Sensor._id}
                    className={classNames("select-group-item", { isSaving: isSaving === "sensor" + watchlistSensor.Sensor._id })}>
                    <div className="row u-full-width watchlist-table-row">
                      <div className="col-xs-1">
                        <CheckboxInput
                          className="watchlists-show-desktop-checkbox"
                          onChange={() => toggleSensorVisibility(watchlistSensor.Sensor._id)}
                          checked={visibleSensors.has(watchlistSensor.Sensor._id)}
                          name={"visibility-" + watchlistSensor.Sensor._id}
                        />
                      </div>
                      <div className="watchlist-legend-wrapper col-xs-1">
                        <div className="legend-wrapper">
                          <div className="watchlist-line" style={{ backgroundColor: watchlistSensor.color }}></div>
                          <div
                            className={`watchlist-shape ${watchlistSensor.shape}`}
                            style={{ backgroundColor: watchlistSensor.color }}></div>
                        </div>
                      </div>

                      <div className="col-xs-4">{watchlistSensor.Sensor.Device?.name || "--"}</div>
                      <div className="col-xs-2">
                        {moment(watchlistSensor.Sensor.updatedAt).format(`${dateFormat} ${timeFormat}`) || "--"}
                      </div>
                      <div className="col-xs-1">
                        {measurementTransform(watchlistSensor.Sensor.current_value, {
                          unit: selectedWatchlist.unit,
                          empirical: isImperialUnit(selectedWatchlist.unit),
                          type: watchlistSensor.Sensor.Sensor_type.type,
                          disableRounding: false,
                        })}{" "}
                        <span className="sub">
                          {displaySensorReadingWithUnit(watchlistSensor.Sensor, isImperialUnit(selectedWatchlist.unit))}
                        </span>
                      </div>
                      <div className="col-xs-2">{watchlistSensor.Sensor.Device?.location_note || "--"}</div>
                      <div className="col-xs-1">
                        <StyledTooltip title={t("watchlists:details.remove_sensor")} enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
                          <button
                            className={classNames("btn btn-plain", {
                              "u-invisible": isSaving === "sensor" + watchlistSensor.Sensor._id,
                            })}
                            onClick={() => deleteSensorFromWatchlist(watchlistSensor.Sensor)}>
                            <i className="fa fa-trash delete-sensor-icon" />
                            <span className="sr-only">{t("watchlists:details.remove_sensor")}</span>
                          </button>
                        </StyledTooltip>
                      </div>
                    </div>
                  </li>
                ))
              ) : (
                <li className="watchlist-sensors-table-empty">{t("watchlists:details.no_added_sensors_to_watchlist")}</li>
              )}
            </ul>
          </div>
        </div>
        <div className="row u-desktop-hide watchlistSensors-mobile-tablet">
          <div className="col-sm-12">
            <ul className="select-group">
              {atLeastOneSensorAvailable && selectedWatchlist.WatchlistSensors ? (
                selectedWatchlist.WatchlistSensors.map((watchlistSensor) => (
                  <li
                    key={watchlistSensor.Sensor._id}
                    className={classNames("select-group-item", { isSaving: isSaving === "watchlistSensor" + watchlistSensor.Sensor._id })}>
                    <div className="u-full-width watchlistSensor-row">
                      <div className="row-1">
                        <div className="field sensors-column">
                          <span className="input-label label">{t("watchlists:table.headers_show")}</span>
                          <CheckboxInput
                            className="show-checkbox"
                            onChange={() => toggleSensorVisibility(watchlistSensor.Sensor._id)}
                            checked={visibleSensors.has(watchlistSensor.Sensor._id)}
                            name={"visibility-" + watchlistSensor.Sensor._id}
                          />
                        </div>
                        <div className="field sensors-column">
                          <span className="input-label label">{t("watchlists:table.headers_legend")}</span>
                          <div className="watchlist-legend-wrapper">
                            <div className="legend-wrapper">
                              <div className="watchlist-line" style={{ backgroundColor: watchlistSensor.color }}></div>
                              <div
                                className={`watchlist-shape ${watchlistSensor.shape}`}
                                style={{ backgroundColor: watchlistSensor.color }}></div>
                            </div>
                          </div>
                        </div>
                        <div className="field sensors-column">
                          <span className="input-label label">{t("watchlists:table.headers_device_name")}</span>
                          <span>{watchlistSensor.Sensor.Device?.name || "--"}</span>
                        </div>
                      </div>

                      <div className="row-2">
                        <div className="field sensors-column">
                          <div className="input-label label">{t("watchlists:table.headers_last_updated")}</div>
                          <span>{moment(watchlistSensor.Sensor.updatedAt).format(`${dateFormat} ${timeFormat}`) || "--"}</span>
                        </div>
                        <div className="field sensors-column value">
                          <span className="input-label label"> {t("watchlists:table.headers_value")}</span>
                          <span>
                            {measurementTransform(watchlistSensor.Sensor.current_value, {
                              unit: watchlistSensor.Sensor.default_unit,
                              empirical: watchlistSensor.Sensor.is_imperial,
                              type: watchlistSensor.Sensor.Sensor_type.type,
                              disableRounding: false,
                            })}
                          </span>
                        </div>
                      </div>

                      <div className="row-3">
                        <div className="field field-checkbox sensors-column">
                          <span className="input-label label">{t("watchlists:table.headers_location")}</span>
                          <span> {watchlistSensor.Sensor.Device?.location_note || "--"}</span>
                        </div>
                        <div className="field remove-sensor sensors-column">
                          <StyledTooltip title={t("watchlists:details.remove_sensor")} enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
                            <button
                              className={classNames("btn btn-plain", {
                                "u-invisible": isSaving === "sensor" + watchlistSensor.Sensor._id,
                              })}
                              onClick={() => deleteSensorFromWatchlist(watchlistSensor.Sensor)}>
                              <i className="fa fa-trash delete-sensor-icon" />
                              <span className="sr-only">{t("watchlists:details.remove_sensor")}</span>
                            </button>
                          </StyledTooltip>
                        </div>
                      </div>
                    </div>
                  </li>
                ))
              ) : (
                <li className="watchlist-sensors-table-empty">{t("watchlists:details.no_added_sensors_to_watchlist")}</li>
              )}
            </ul>
          </div>
        </div>
      </>
    );
  },
);
