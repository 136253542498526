import React, { FC } from "react";
import { Button } from "@mui/material";
import { FormFieldCheckbox } from "../../Components";
import { resetSystemMessageClosedFlag } from "../../Managers/ManageSystemMessageService";
import { fetchSystemMessagesData, getUserDateFormat, showSnackbar } from "../../AppState";
import { formatDateCustom } from "../../Managers";
import { ISystemMessage } from "../../Managers/Types";
import { useTranslation } from "react-i18next";
import { FormikErrors, FormikValues } from "formik";
import { getTimeFormatValueForUser } from "../../Enums/TimeFormat";

interface ILastInvalidationSection {
  systemMessage: ISystemMessage;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => Promise<void | FormikErrors<FormikValues>>;
  setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => Promise<void | FormikErrors<FormikValues>>;
}

export const LastInvalidationSection: FC<ILastInvalidationSection> = ({ systemMessage, setFieldValue, setFieldTouched }) => {
  const { t } = useTranslation(["system_message"]);

  const resetUsersSystemMessageClosed = () => {
    if (!systemMessage?._id) {
      return;
    }

    resetSystemMessageClosedFlag(systemMessage._id)
      .then((r) => {
        // setSelectedSystemMessage({ ...systemMessage, last_invalidation_date: r.last_invalidation_date }); // TODO why times are different
        fetchSystemMessagesData();
        showSnackbar(t("system_message:invalidation_success"), "success");
      })
      .catch((e) => {
        console.log("Error while resetting UsersSystemMessageClosed", e);
        showSnackbar(t("system_message:emails_sent_error"), "error");
      });
  };

  const formatDate = (date: Date) => {
    const dateFormat = getUserDateFormat();
    const timeFormat = getTimeFormatValueForUser();

    return formatDateCustom(date, `${dateFormat} ${timeFormat}`);
  };

  return (
    <div className="row">
      <Button disabled={!systemMessage._id} variant="contained" onClick={() => resetUsersSystemMessageClosed()}>
        {t(`system_message:invalidate_display`)}
      </Button>

      {!systemMessage.last_invalidation_date ? null : (
        <span style={{ paddingLeft: 8 }} className="last-invalidation-time">
          {t(`system_message:last_invalidation`)} {formatDate(systemMessage.last_invalidation_date)}
        </span>
      )}

      <div style={{ marginTop: "2rem" }}>
        <FormFieldCheckbox
          name="active"
          checked={systemMessage.active}
          label={t(`system_message:active_label`)}
          onChange={(e) => {
            setFieldTouched("active", true);
            setFieldValue("active", e.target.checked);
          }}
        />
      </div>
    </div>
  );
};
