import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { INotification } from "../../../Managers/Types";
import { addAcknowledgementMessage, styleAcknowledgementNotes } from "../../../Managers/NotificationService";

interface INotesStep {
  notification: INotification;
  refetchNotifications: () => void;
  setNotification: (notification: INotification) => void;
}

export const NotesStep: React.FC<INotesStep> = observer(({ notification, refetchNotifications, setNotification }) => {
  const { t } = useTranslation(["alert_resolve", "alerts"]);
  const [alertAcknowledgeNote, setAlertAcknowledgeNote] = useState<string>("");
  const notesListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (notesListRef.current) {
      notesListRef.current.scrollTop = notesListRef.current.scrollHeight;
    }
  }, [notification.acknowledgement_notes]);

  const addNote = async () => {
    try {
      if (alertAcknowledgeNote) {
        const updatedNotification = await addAcknowledgementMessage(notification, alertAcknowledgeNote);
        setNotification({
          ...notification,
          acknowledged_by_user_ids: updatedNotification.acknowledged_by_user_ids || [],
          acknowledgement_notes: updatedNotification.acknowledgement_notes || [],
        });
        setAlertAcknowledgeNote("");
        refetchNotifications();

        if (notesListRef.current) {
          notesListRef.current.scrollTop = notesListRef.current.scrollHeight;
        }
      }
    } catch (error) {
      console.error("Failed to add note:", error);
    }
  };

  return (
    <div className="notes-step">
      <div>
        <label className="input-label" htmlFor="protocol-info">
          {t("alert_resolve:alert_protocol")}
        </label>
        <textarea name="protocol-info" rows={4} className="input-textarea disabled input" readOnly>
          {notification.Alert.protocol || "No protocol provided."}
        </textarea>
      </div>
      <div>
        <label htmlFor="notes" className="input-label">
          {t("alert_resolve:notes")}
        </label>
        <div className="notes-list custom-scrollbar" ref={notesListRef}>
          {styleAcknowledgementNotes(notification.acknowledgement_notes).map((note: string, index) => (
            <div key={index} className="history-note" dangerouslySetInnerHTML={{ __html: note }}></div>
          ))}
        </div>
        <textarea
          name="notes"
          rows={2}
          cols={80}
          className="input input-textarea"
          placeholder={t("alert_resolve:add_response_placeholder")}
          value={alertAcknowledgeNote}
          onChange={(e) => setAlertAcknowledgeNote(e.target.value)}
        />
        <div className="save-note-button-wrapper">
          <button className="btn btn-primary save-note-button" onClick={addNote} disabled={!alertAcknowledgeNote.trim().length}>
            {t("common:save")}
          </button>
        </div>
      </div>
    </div>
  );
});
