import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import {
  checkForWai418HumidityDevice,
  checkForWai418TemperatureDevice,
  formatDateCustom,
  getDeviceState,
  getNameSlug,
  myIcon,
  updateDevice,
} from "../../Managers";
import { alertConditionTransform } from "../../Managers/AlertConditionService";
import { measurementTransform } from "../../Managers/MeasurementService";
import { AppState, getUserDateFormat, refreshLocationData, showAppModal } from "../../AppState";
import { unitsTransform } from "../../Managers/UnitsService";
import { IAlert, IDevice, ISensor } from "../../Managers/Types";
import { getLatestFirmwares } from "../../Managers/API";
import "./DeviceCard.scss";
import { MenuItem, StyledTooltip, useScreenMode } from "../../Components";
import { useTranslation } from "react-i18next";
import ClickAwayListener from "react-click-away-listener";
import { DevicePropertiesModal } from "./Modals/DevicePropertiesModal";
import { AddToGroupModal } from "./Modals/AddToGroupModal";
import { ManageFirmware } from "./Modals/ManageFirmware";
import { OfflineIcon } from "../../icon";
import { Colors } from "../../Theme";
import { WindowSize } from "../../Enums";
import moment from "moment";
import { BatteryIcon } from "./BatteryIcon";
import { SignalIcon } from "./SignalIcon";
import { getTimeFormatValueForUser } from "../../Enums/TimeFormat";
import { DeviceDetailModalDeviceLayer } from "./Modals/DeviceDetailModal/DeviceDetailModalDeviceLayer";

interface IDeviceCardProps {
  isWai418?: boolean;
  cardDevice: IDevice;
  refresh: () => void;
}

const SensorTileContent: React.FC<{
  deviceSampleInterval?: number;
  isWai418?: boolean;
  isEmpirical: boolean;
  convertToRh: boolean;
  convertToTemp: boolean;
  sensor: ISensor;
  deviceState: string;
}> = observer(({ deviceSampleInterval, deviceState, sensor, convertToRh, isEmpirical, convertToTemp, isWai418 }) => {
  const [value, setValue] = useState<number | string>();

  useEffect(() => {
    const timeFromTheLastReport = moment().diff(moment(sensor.last_report_time), "seconds").valueOf();
    if (
      sensor.last_report_time &&
      deviceSampleInterval &&
      timeFromTheLastReport < deviceSampleInterval + 0.1 * deviceSampleInterval &&
      deviceState !== "offline"
    ) {
      setValue(
        measurementTransform(sensor.current_value, {
          unit: sensor.default_unit,
          empirical: isEmpirical,
          type: sensor.Sensor_type.type,
          disableRounding: false,
          convertToHumidity: convertToRh,
          convertToTemperature: convertToTemp,
        }),
      );
    } else {
      setValue("--.-");
    }
  }, [deviceSampleInterval, sensor]);

  const displaySensorReadingWithUnit = () => {
    if (sensor.Sensor_type.type === "BOOLEAN") {
      return "";
    }

    return sensor.display_unit !== "" && sensor.default_unit !== sensor.display_unit
      ? sensor.display_unit
      : unitsTransform(sensor.default_unit, [sensor.default_unit, isEmpirical, sensor.Sensor_type.type, convertToRh, convertToTemp]);
  };

  return (
    <p className="device-card-value">
      <span className="device-card-value-side">
        {!isWai418 ? (
          <i className={`device-card-icon icon icon-${myIcon(sensor.default_unit === "%" ? "set_point" : sensor.Sensor_type.name)}`} />
        ) : null}
      </span>
      <span className="device-card-value-main">{value}</span>
      <span className={"device-card-value-side-sub"} key={sensor.display_unit}>
        {displaySensorReadingWithUnit()}
      </span>
    </p>
  );
});

export const DeviceCard: React.FC<IDeviceCardProps> = observer(({ refresh, cardDevice, isWai418 }) => {
  const [state, setState] = useState(cardDevice.is_online ? "online" : "offline"); // // => 'new', 'alert', 'warning', 'offline'
  const [selectedSensorId, setSelectedSensorId] = useState(0);
  const [sensors, setSensors] = useState<ISensor[]>([]);
  const [selected, setSelected] = useState(false);
  const [convertToRh, setConvertToRh] = useState(false);
  const [convertToTemp, setConvertToTemp] = useState(false);
  const [showDeviceMenu, setShowDeviceMenu] = useState(false);

  const { t } = useTranslation("dashboard");
  const mode = useScreenMode();

  const handleCheckDetail = (device: IDevice, sensorId?: number) => {
    showAppModal(<DeviceDetailModalDeviceLayer deviceId={device._id} refresh={refresh} sensorId={sensorId} />);
  };

  const dateFormat = getUserDateFormat();
  const timeFormat = getTimeFormatValueForUser();

  const getNewState = () => {
    let newDevices: any[] = JSON.parse(window.localStorage.getItem("newDevices") || "[]");
    if (newDevices && newDevices.length) {
      newDevices.forEach((device) => {
        if (device._id === cardDevice._id) {
          setState("new");
          return;
        }
      });
    }
  };

  const clearNewState = (e: any, newState?: string) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    // set the device state to null or the set newState
    if (state === "new") {
      setState(newState || "");

      let newDevices = JSON.parse(window.localStorage.getItem("newDevices") || "[]");
      newDevices = newDevices.filter((device: any) => device._id !== cardDevice._id);
      window.localStorage.setItem("newDevices", JSON.stringify(newDevices));
    }
  };

  let states: Record<string, { type: string; alert: IAlert }> = {};
  let deviceCondition: string = "";

  const setDeviceStatus = () => setState(cardDevice.is_online ? "online" : "offline");

  useEffect(() => {
    if (AppState.selectedDeviceIds.includes(cardDevice._id)) {
      setSelected(true);
    } else {
      setSelected(false);
    }

    if (isWai418) {
      if (checkForWai418TemperatureDevice(cardDevice.serial_number)) {
        setConvertToTemp(true);
      } else if (checkForWai418HumidityDevice(cardDevice.serial_number)) {
        setConvertToRh(true);
      }
    }

    if (cardDevice.is_online) {
      setDeviceStatus();
      const deviceState = getDeviceState(cardDevice);

      states = deviceState.states;
      if (deviceState.deviceCondition) {
        deviceCondition = deviceState.deviceCondition;
      }
      if (deviceCondition) {
        clearNewState(null, deviceCondition.toLowerCase());
        setState(deviceCondition.toLowerCase());
      } else {
        getNewState();
      }
    } else {
      setDeviceStatus();
    }

    updateSelectedSensor();
  }, [cardDevice, AppState.selectedDeviceIds]);

  const filterVisibleSensors = (sensor: ISensor) => {
    const userId = AppState?.user?._id;
    if (typeof userId !== "number") return false;

    return !sensor.hide_on_dashboard_for_user_ids || !sensor.hide_on_dashboard_for_user_ids.includes(userId);
  };

  const updateSelectedSensor = () => {
    if (!cardDevice.Sensors || !cardDevice.Sensors.length) return;

    let filteredSensors = cardDevice.Sensors.filter((sensor) => {
      return (
        sensor.Sensor_type &&
        sensor.Sensor_type.name !== "Battery Voltage" &&
        sensor.Sensor_type.name !== "Signal Strength" &&
        sensor.Sensor_type.name.toLowerCase() !== "co2e" &&
        filterVisibleSensors(sensor)
      );
    });

    if (!cardDevice.primarySensorId && filteredSensors.length > 0) {
      setSelectedSensorId(filteredSensors[0]._id);
      setSensors(filteredSensors.slice(1));
    } else {
      const newSensors = [] as any[];
      filteredSensors.forEach((sensor) => {
        if (sensor._id !== cardDevice.primarySensorId) {
          newSensors.push(sensor);
        } else {
          setSelectedSensorId(sensor._id);
        }
      });
      setSensors(newSensors);
    }
  };

  const selectSensor = (sensor: ISensor) => {
    const userId = AppState?.user?._id;

    if (userId && sensor.hide_on_dashboard_for_user_ids?.includes(userId)) {
      const visibleSensors = cardDevice.Sensors.filter(filterVisibleSensors);
      if (visibleSensors.length > 0) {
        const newSelectedSensor = visibleSensors[0];
        setSensors(visibleSensors);
        setSelectedSensorId(newSelectedSensor._id);

        const newDevice = { ...cardDevice, primarySensorId: newSelectedSensor._id };
        updateDevice(newDevice).then(() => refresh());
      }
    } else {
      const allSensorsExceptSelected = cardDevice.Sensors.filter((s) => sensor._id !== s._id);
      const newSensors = [...allSensorsExceptSelected];
      setSensors(newSensors);
      setSelectedSensorId(sensor._id);

      const newDevice = { ...cardDevice, primarySensorId: sensor._id };
      updateDevice(newDevice).then(() => refresh());
    }
  };

  const selectedSensor =
    cardDevice.Sensors.filter(filterVisibleSensors).find((sensor) => sensor._id === selectedSensorId) ||
    (cardDevice.Sensors.filter(filterVisibleSensors).length > 0 ? cardDevice.Sensors.filter(filterVisibleSensors)[0] : null);

  const actions = [
    {
      title: t("dashboard:device.turn", { context: cardDevice.is_online ? "offline" : "online" }),
      action: () => updateDevice({ ...cardDevice, is_online: !cardDevice.is_online }).then(() => refresh()),
      icon: "fa-power-off",
    },
    {
      title: t("dashboard:action_menu.edit_properties"),
      action: () => showAppModal(<DevicePropertiesModal device={cardDevice} refresh={refresh} />),
      icon: "fa-pencil",
    },
    {
      title: !cardDevice.DeviceGroupId ? t("dashboard:action_menu.add_to_group") : t("dashboard:action_menu.remove_group"),
      action: () =>
        showAppModal(
          <AddToGroupModal
            devices={[cardDevice]}
            title={!cardDevice.DeviceGroupId ? t("dashboard:action_menu.add_to_group") : t("dashboard:action_menu.remove_group")}
            remove={!!cardDevice.DeviceGroupId}
          />,
        ),
      icon: !cardDevice.DeviceGroupId ? "fa-object-group" : "fa-object-ungroup",
    },
    {
      title: t("dashboard:device.manage_firmware"),
      action: async () => {
        refreshLocationData();
        showAppModal(<ManageFirmware latestFirmwares={await getLatestFirmwares()} selectedDevice={cardDevice} />);
      },
      icon: "fa-cloud-upload",
    },
  ];

  return (
    <div className={classNames("device-card-wrapper", { large: sensors && sensors.length })}>
      <article
        className={classNames(`device-card ${state}`, {
          "device-card-large": sensors && sensors.length,
          selected,
        })}
        onClick={clearNewState}>
        <header className="device-card-header" aria-label={cardDevice.name}>
          <div className="device-card-title-row">
            <h1 className="device-card-title">{cardDevice.name || "SR# " + cardDevice.serial_number}</h1>
            <div className="device-card-info-icons">
              <SignalIcon signalStrength={cardDevice.signal_strength} />

              <BatteryIcon battery={cardDevice.battery} />

              <ClickAwayListener onClickAway={() => setShowDeviceMenu(false)}>
                <button
                  className={classNames("btn btn-plain bapi-header-nav-item dropdown u-text-teal device-menu", { open: showDeviceMenu })}
                  onClick={() => setShowDeviceMenu(!showDeviceMenu)}>
                  <i className="fa fa-ellipsis-v" style={{ fontSize: "1.8em", width: "0.6em" }} />

                  <section role="menu" className="dropdown-menu dropdown-menu-right">
                    <ul className="dropdown-list">
                      {actions.map((item) => (
                        <MenuItem key={item.title} role="menuitem" className="dropdown-menu-item" onClick={item.action}>
                          <i className={`fa ${item.icon}`} />
                          <span>{item.title}</span>
                        </MenuItem>
                      ))}
                    </ul>
                  </section>
                </button>
              </ClickAwayListener>
            </div>
          </div>
          <div className="device-card-info">
            <p className="device-card-info-text">
              {cardDevice.location_note && cardDevice.location_note !== "{}" ? (
                <>
                  <span>
                    <StyledTooltip title={t("dashboard:device_card.device_placement")}>
                      <i className="fa fa-map-marker" />
                    </StyledTooltip>
                  </span>
                  {cardDevice.location_note}
                </>
              ) : null}
              <br />
              {t("dashboard:device_card.last_updated")}: {formatDateCustom(cardDevice.last_report_time, `${timeFormat} ${dateFormat}`)}
            </p>
            <div style={{ alignSelf: "end", whiteSpace: "nowrap" }}>
              <StyledTooltip title={t("dashboard:device.state", { context: state })}>
                <span className={classNames("icon-sprite", `icon-sprite-${state}`)} />
              </StyledTooltip>
              {state === "offline" ? (
                <StyledTooltip title={t("dashboard:device.offline")}>
                  <span>
                    <OfflineIcon color={Colors.warning} />
                  </span>
                </StyledTooltip>
              ) : null}
            </div>
          </div>
        </header>

        <section className={classNames("device-card-body", { "reverse-layout": sensors?.length > 2 })}>
          <div
            className={classNames(
              "device-card-body-main",
              selectedSensor && states[selectedSensor._id] ? states[selectedSensor._id].type.toLowerCase() : "",
            )}
            onClick={() => mode === WindowSize.DESKTOP && handleCheckDetail(cardDevice, selectedSensor?._id)}>
            {selectedSensor && selectedSensor.Sensor_type ? (
              <p className="device-card-value-label">
                {convertToRh
                  ? t("sensor_types:humidity")
                  : convertToTemp
                  ? t("sensor_types:temperature")
                  : selectedSensor.display_name || t(`sensor_types:${getNameSlug(selectedSensor.Sensor_type.name)}`)}
              </p>
            ) : null}
            {selectedSensor ? (
              <SensorTileContent
                deviceState={state}
                deviceSampleInterval={cardDevice.Device_setting?.settings_data.transmitInterval ?? 3600}
                isWai418={isWai418}
                isEmpirical={selectedSensor.is_imperial}
                convertToRh={convertToRh}
                convertToTemp={convertToTemp}
                sensor={selectedSensor}
              />
            ) : (
              <>
                <p className="device-card-value-label">{t("dashboard:device_card.no_sensor_data")}</p>
                <p className="device-card-value"></p>
              </>
            )}

            <div>
              <button
                onClick={() => handleCheckDetail(cardDevice, selectedSensor?._id)}
                className="btn btn-plain u-desktop-hide u-text-teal show-details-button">
                {t("common:details")}
              </button>
            </div>

            {selectedSensor && states[selectedSensor._id] ? (
              <div className="device-card-footer">
                {/* this should only happen in warning and alert state */}
                {alertConditionTransform(states[selectedSensor._id].alert)}
              </div>
            ) : null}
          </div>

          {sensors?.length &&
          sensors.filter(filterVisibleSensors).filter((sensor: ISensor) => selectedSensor?._id !== sensor._id).length ? (
            <div className="device-card-body-aside">
              {sensors
                .filter(filterVisibleSensors)
                .filter((sensor: ISensor) => selectedSensor?._id !== sensor._id)
                .slice(0, 3)
                .map((sensor) => (
                  <div
                    key={sensor._id}
                    className={`device-card-body-aside-item ${states[sensor._id] ? states[sensor._id].type.toLowerCase() : ""}`}
                    onClick={() => selectSensor(sensor)}>
                    <SensorTileContent
                      deviceState={state}
                      deviceSampleInterval={cardDevice.Device_setting?.settings_data.transmitInterval ?? 3600}
                      isWai418={isWai418}
                      isEmpirical={sensor.is_imperial}
                      convertToRh={convertToRh}
                      convertToTemp={convertToTemp}
                      sensor={sensor}
                    />
                  </div>
                ))}
            </div>
          ) : null}
        </section>
      </article>
    </div>
  );
});
