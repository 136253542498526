import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  HeaderRow,
  ICON_TOOLTIP_DEFAULT_DELAY,
  ILineChartValue,
  ISetPoint,
  LeftRail,
  Page,
  RangePicker,
  SelectInput,
  StyledTooltip,
  useScreenMode,
  Wrapper,
  ZoomDates,
} from "../../Components";
import { ConfirmModal } from "../../Modals";
import { showAppModal, showSnackbar } from "../../AppState";
import { mySearch, prepareChartDataSet, debounce } from "../../Managers";
import { WindowSize } from "../../Enums";
import { useTranslation } from "react-i18next";
import "./Watchlists.scss";
import { Drawer } from "@mui/material";
import { LeftRailToggleIcon } from "../../icon";
import styled from "@emotion/styled/macro";
import { EditWatchlistModal } from "../Dashboard/Modals/EditWatchlistModal";
import { useWatchlists } from "../../Managers/API";
import { IWatchlist } from "../../Managers/Types";
import moment from "moment/moment";
import { destroyWatchlist } from "../../Managers/WatchlistsService";
import { getDeviceSensorData, ISensorDatapoint } from "../../Managers/DeviceService";
import { measurementTransform } from "../../Managers/MeasurementService";
import { getNotificationsHistory } from "../../Managers/NotificationService";
import { GraphController } from "./GraphController";
import { WatchlistTable } from "./WatchlistTable";
import { isImperialUnit } from "../../Managers/UnitsService";

const shapes: Array<"square" | "circle" | "triangle" | "diamond"> = ["square", "circle", "triangle", "diamond"];

const colors = ["#00d1a4", "#fa2fb5", "#06bdf7", "#ffa216"];

export const Watchlists: React.FC = observer(() => {
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchWatchlistString, setSearchWatchlistString] = useState("");
  const [isSaving, setIsSaving] = useState("");
  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(moment().subtract(24, "hours").toDate());
  const [graphData, setGraphData] = useState<ILineChartValue[]>([]);
  const [isGraphLoading, setIsGraphLoading] = useState(true);
  const [setPoints, setSetPoints] = useState<ISetPoint[]>([]);
  const [selectedWatchlist, setSelectedWatchlist] = useState<IWatchlist | null>();
  const [visibleSensors, setVisibleSensors] = useState<Set<number>>(new Set());

  const mode = useScreenMode();

  const { t } = useTranslation(["watchlists", "common", "sensor_types"]);

  const MobileHeaderRow = styled.div({
    display: "flex",
    gap: 16,
  });

  const setDates = (start: Date, end: Date) => {
    handleStartDateChange(start);
    handleEndDateChange(end);
  };

  const handleStartDateChange = (d: Date) => {
    setStartDate(d);
  };

  const handleEndDateChange = (d: Date) => {
    setEndDate(d);
  };

  const watchlists = useWatchlists();
  const sortedWatchlists = mySearch<IWatchlist>([...(watchlists.data || [])], searchWatchlistString);
  sortedWatchlists.sort((a, b) =>
    order === "asc" ? a.name.toLowerCase().localeCompare(b.name.toLowerCase()) : b.name.toLowerCase().localeCompare(a.name.toLowerCase()),
  );

  const atLeastOneSensorAvailable = selectedWatchlist
    ? selectedWatchlist.WatchlistSensors && selectedWatchlist.WatchlistSensors.length > 0
    : false;

  const setupSelectedWatchlist = useCallback(
    async (watchlist?: IWatchlist) => {
      setIsGraphLoading(true);
      if (watchlist && watchlist._id !== selectedWatchlist?._id) {
        await refreshWatchlistsView(watchlist);
      }
    },
    [selectedWatchlist],
  );

  useEffect(() => {
    if (selectedWatchlist?.WatchlistSensors) {
      const allSensorIds = selectedWatchlist.WatchlistSensors.map((watchlistSensor) => watchlistSensor.Sensor._id);
      setVisibleSensors(new Set(allSensorIds));
    }
  }, [selectedWatchlist?.WatchlistSensors]);

  const fetchData = useCallback(
    debounce(async () => {
      if (selectedWatchlist) {
        await _getSensorData(selectedWatchlist, startDate, endDate);
      }
    }, 500),
    [selectedWatchlist, startDate, endDate, visibleSensors],
  );

  useEffect(() => {
    fetchData();
  }, [startDate, endDate, selectedWatchlist, visibleSensors]);

  useEffect(() => {
    if (!selectedWatchlist && sortedWatchlists.length > 0) {
      const preSelected = sortedWatchlists[0];
      setupSelectedWatchlist(preSelected);
      console.log("No watchlist config selected yet, pre-selecting", preSelected);
    } else {
      const selected = sortedWatchlists.find((a) => a._id === selectedWatchlist?._id);
      setSelectedWatchlist(selected || selectedWatchlist);
    }
  }, [watchlists, selectedWatchlist, sortedWatchlists]);

  const refreshWatchlistsView = async (watchlist: IWatchlist) => {
    setIsGraphLoading(true);
    setSelectedWatchlist(watchlist);
  };

  const _getSensorData = async (selectedWatchlist: IWatchlist, startDate: Date, endDate: Date) => {
    if (!selectedWatchlist?.WatchlistSensors || !selectedWatchlist.WatchlistSensors.length) {
      return;
    }

    setIsGraphLoading(true);
    setGraphData([]);
    setSetPoints([]);

    const isWatchlistImperial = isImperialUnit(selectedWatchlist.unit);
    const sensorIds = selectedWatchlist.WatchlistSensors.map((item) => item.Sensor._id);
    const historicalData = await getNotificationsHistory(
      moment(startDate).startOf("day").toISOString(),
      moment(endDate).endOf("day").toISOString(),
    );

    const allSetPoints = historicalData
      .filter((alert) => sensorIds.some((sensorId) => sensorId === alert.Sensor?._id))
      .map((alert, index) => {
        return {
          x: new Date(alert.createdAt),
          y: measurementTransform(alert.value.value, {
            unit: alert.Sensor?.default_unit,
            empirical: isWatchlistImperial,
            type: alert.Sensor?.Sensor_type.type,
          }),
          _id: alert.Sensor?._id || Math.random(),
          unit: alert.Sensor?.default_unit ?? "",
          triggeredTarget: null,
          color: colors[index % colors.length],
          shape: shapes[index % shapes.length],
        };
      });

    const allSensorDataPromises = selectedWatchlist.WatchlistSensors.map((item, index) => {
      if (!visibleSensors.has(item.Sensor._id)) return Promise.resolve([]);

      return getDeviceSensorData(item.Sensor, startDate.toISOString(), endDate.toISOString(), false).then((response) => {
        response.forEach((setPoint: ISensorDatapoint) => {
          setPoint.color = colors[index % colors.length];
          setPoint.shape = shapes[index % shapes.length];
        });

        return response;
      });
    });

    Promise.all(allSensorDataPromises)
      .then((responses) => {
        const allSensorData = responses.flatMap((r, index) => {
          if (r && r.length) {
            const sensor = selectedWatchlist.WatchlistSensors![index].Sensor;
            return prepareChartDataSet(r, sensor, isWatchlistImperial);
          }
          return [];
        });

        setGraphData(allSensorData);
        setSetPoints(allSetPoints);
      })
      .catch((e) => {
        const errorMessage = t("watchlist:sensor_data_load_error");
        showSnackbar(errorMessage, "error");
        console.log(errorMessage, e);
      })
      .finally(() => {
        setIsGraphLoading(false);
      });
  };

  const removeWatchlist = () => {
    setIsSaving("watchlist-" + (selectedWatchlist?._id || 0));
    selectedWatchlist &&
      destroyWatchlist(selectedWatchlist?._id || 0)
        .then((r) => {
          console.log("deleted", r);
          showSnackbar(t("watchlists:list.watchlist_delete_success"), "success");
          showAppModal(null);
        })
        .catch((e) => {
          showSnackbar(t("watchlists:list.watchlist_delete_error", { name: selectedWatchlist?.name || "" }), "error");
          console.log("delete error", e);
        });
  };

  return (
    <Page>
      <Wrapper>
        <Drawer
          variant={mode === WindowSize.DESKTOP ? "permanent" : "temporary"}
          hideBackdrop={mode === WindowSize.DESKTOP}
          onBackdropClick={() => mode === WindowSize.TABLET && setDrawerOpen(false)}
          open={drawerOpen || mode === WindowSize.DESKTOP}>
          <LeftRail
            header={
              <div className="input-holder u-full-width">
                <input
                  type="text"
                  className="input input-default"
                  placeholder={t("watchlists:list.find_watchlist")}
                  value={searchWatchlistString}
                  onChange={(e) => setSearchWatchlistString(e.target.value)}
                />
                <i className="fa fa-search input-holder-icon" />
              </div>
            }
            body={
              <nav className="left-rail-nav">
                <div className="left-rail-nav-header">
                  <h2 className="left-rail-nav-header-title mod-with-btn">
                    <StyledTooltip
                      title={t("common:sort_by_name", { context: order === "asc" ? "desc" : "asc" })}
                      enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
                      <button className="btn btn-plain" onClick={() => setOrder(order === "asc" ? "desc" : "asc")}>
                        {order === "asc" ? <i className="fa fa-sort-amount-desc" /> : <i className="fa fa-sort-amount-asc" />}
                      </button>
                    </StyledTooltip>{" "}
                    {t("watchlists:list.left_rail.title")}
                  </h2>

                  <button className="btn btn-circle btn-primary" onClick={() => showAppModal(<EditWatchlistModal mode="new" />)}>
                    <i className="fa fa-plus" />
                    <span className="sr-only">{t("watchlists:list.add_watchlist")}</span>
                  </button>
                </div>
                <ul className="left-rail-nav-group">
                  {sortedWatchlists.length < 1 ? (
                    <li className="left-rail-nav-item">{t("watchlists:list.no_watchlists")}</li>
                  ) : (
                    <>
                      {sortedWatchlists.map((watchlist) => (
                        <li
                          key={watchlist._id}
                          className={classNames("left-rail-nav-item", { active: watchlist._id === selectedWatchlist?._id })}>
                          <div onClick={() => setupSelectedWatchlist(watchlist)} title={watchlist.name} className="u-text-eclipse">
                            {watchlist.name}
                          </div>
                        </li>
                      ))}
                    </>
                  )}
                </ul>
              </nav>
            }
          />
        </Drawer>

        {sortedWatchlists.length < 1 ? (
          <main className="bapi-main mod-dashboard bapi-no-watchlists-dashboard">
            <HeaderRow info={{ name: "" }} />
            <div className={classNames("main-panel no-watchlists-panel u-text-center", { isLoading: watchlists.isLoading })}>
              <p>{t("watchlists:list.no_watchlists")}</p>

              <StyledTooltip title={t("watchlists:list.create_watchlist")} enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
                <button className="btn btn-primary" onClick={() => showAppModal(<EditWatchlistModal mode="new" />)}>
                  {t("watchlists:list.create_watchlist_button")}
                </button>
              </StyledTooltip>
            </div>
          </main>
        ) : selectedWatchlist ? (
          <main className="bapi-main mod-dashboard bapi-watchlists-dashboard">
            <HeaderRow
              className="u-mobile-hide"
              infoLeftSideControl={
                <div className="left-rail-toggle-wrapper watchlists-left-rail-icon-wrapper u-tablet-only">
                  <button className="btn btn-icon" style={{ display: "flex" }} onClick={() => setDrawerOpen(!drawerOpen)}>
                    <LeftRailToggleIcon />
                  </button>
                </div>
              }
              info={{
                name: selectedWatchlist.name,
              }}
              fullWidth={false}
              infoControl={
                <>
                  <div className="header-row-info-control">
                    <StyledTooltip title={t("common:edit")} enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
                      <button
                        className="btn btn-icon"
                        onClick={() => {
                          showAppModal(<EditWatchlistModal mode={"edit"} watchlist={selectedWatchlist} />);
                        }}>
                        <i className="fa fa-edit u-text-teal mobile-tablet-icon" />
                        <span className="sr-only">{t("common:edit")}</span>
                      </button>
                    </StyledTooltip>

                    <StyledTooltip title={t("common:delete")} enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
                      <button
                        className="btn btn-icon"
                        onClick={() => {
                          showAppModal(
                            <ConfirmModal
                              header={t("watchlists:details.remove")}
                              children={<p>{t("watchlists:details.confirm_delete", { name: selectedWatchlist?.name })}</p>}
                              confirmText={t("watchlists:details.remove")}
                              onConfirm={removeWatchlist}
                            />,
                          );
                        }}>
                        <i className="fa fa-trash u-text-teal mobile-tablet-icon" />
                        <span className="sr-only">{t("common:delete")}</span>
                      </button>
                    </StyledTooltip>
                  </div>
                </>
              }
            />

            <MobileHeaderRow className="header-row-watchlists-mobile-only u-mobile-only">
              <SelectInput
                label={t("watchlists:watchlist_list")}
                className="input-filter-holder"
                style={{ flex: 1, marginTop: "1rem" }}
                displayEmpty={true}
                menuItemClass="dark"
                inputClassName="dark"
                value={selectedWatchlist ? selectedWatchlist._id : ""}
                onChange={(watchlistId) => {
                  const watchlist = sortedWatchlists.find((watchlist) => watchlist._id === watchlistId);
                  if (watchlist) {
                    setupSelectedWatchlist(watchlist);
                  }
                }}
                options={[
                  ...sortedWatchlists.map((watchlist) => ({
                    value: watchlist._id,
                    label: watchlist.name,
                    key: watchlist?._id ? watchlist?._id.toString() : `${Math.random().toString(36).substring(2, 9)}`,
                  })),
                ]}
              />
            </MobileHeaderRow>

            <section className="watchlists-mobile-first-section u-mobile-only">
              <div>
                <div className="watchlist-name">{selectedWatchlist?.name}</div>
              </div>

              <div className="header-row-info-control u-mobile-only">
                <StyledTooltip title={t("common:edit")} enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
                  <button
                    className="btn btn-icon"
                    // TODO probably shouldn't be modal
                    onClick={() => showAppModal(<EditWatchlistModal mode="edit" watchlist={selectedWatchlist} />)}>
                    <i className="fa fa-edit u-text-teal mobile-tablet-icon" />
                    <span className="sr-only">{t("common:edit")}</span>
                  </button>
                </StyledTooltip>
                <StyledTooltip title={t("common:delete")} enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
                  <button
                    className="btn btn-icon"
                    onClick={() => {
                      showAppModal(
                        <ConfirmModal
                          header={t("watchlists:details.remove")}
                          children={<p>{t("watchlists:details.confirm_delete", { name: selectedWatchlist?.name })}</p>}
                          confirmText={t("watchlists:details.remove")}
                          onConfirm={removeWatchlist}
                        />,
                      );
                    }}>
                    <i className="fa fa-trash u-text-teal mobile-tablet-icon" />
                    <span className="sr-only">{t("common:delete")}</span>
                  </button>
                </StyledTooltip>
              </div>
            </section>

            <section className={classNames("main-panel main-panel-watchlists", { isLoading: watchlists.isLoading })}>
              <div
                className="row"
                style={{
                  display: "flex",
                  marginTop: "7px",
                  marginBottom: 0,
                  padding: 0,
                }}>
                <div className="u-mobile-hide" style={{ marginBottom: 8, marginRight: 32, padding: 0 }}>
                  <label htmlFor="zoom" className="input-label u-mobile-hide">
                    {t("common:zoom")}
                  </label>
                  <ZoomDates onSelectOption={(start, end) => setDates(start, end)} optionsToHide={["3M"]} />
                </div>
                <div style={{ padding: 0 }}>
                  <RangePicker
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    className="vertical"
                    hasTimeSelector={true}
                  />
                </div>
              </div>
              <div className="row u-mobile-only" style={{ textAlign: "center", marginTop: "1rem" }}>
                <label htmlFor="zoom" className="input-label">
                  {t("common:zoom")}
                </label>
                <ZoomDates onSelectOption={(start, end) => setDates(start, end)} optionsToHide={["3M"]} />
              </div>

              <GraphController
                data={graphData}
                setPoints={setPoints}
                isLoading={isGraphLoading}
                atLeastOneSensorAvailable={!!atLeastOneSensorAvailable}
              />

              <WatchlistTable
                isSaving={isSaving}
                setIsSaving={setIsSaving}
                refreshWatchlistsView={refreshWatchlistsView}
                sortedWatchlists={sortedWatchlists}
                selectedWatchlist={selectedWatchlist}
                visibleSensors={visibleSensors}
                setVisibleSensors={setVisibleSensors}
                atLeastOneSensorAvailable={!!atLeastOneSensorAvailable}
              />
            </section>
          </main>
        ) : (
          <section className="main-panel no-results">
            <div className="setup-instruction" style={{ margin: "auto", width: "50%" }}>
              <i className="fa fa-info-circle" />
              <p>{t("watchlists:details.select_watchlist_instruction")}</p>
            </div>
          </section>
        )}
      </Wrapper>
    </Page>
  );
});
