import { AppState, getUserDateFormat } from "../AppState";
import { Endpoint, invalidateQuery } from "./API";
import { useQuery } from "react-query";
import { IAccount, IAccountInfo } from "../Views/Accounts/Types";
import moment from "moment";
import { NotificationTypes } from "../Enums/NotificationTypes";
import { IAccountDeviceCount } from "../Views/Accounts";
import { IBillingInfo } from "./Types";
import { getTimeFormatValueForUser } from "../Enums/TimeFormat";

const QK_ACCOUNTS = ["ACCOUNTS"];

const API_PATH = "api/accounts";

export const getAllAccounts = () =>
  Endpoint.get<IAccount[]>(`${API_PATH}/all`).then((r) => {
    invalidateQuery(QK_ACCOUNTS);
    return r.data;
  });

export const getDeviceCount = () =>
  Endpoint.get<IAccountDeviceCount>(`${API_PATH}/device_count`).then((r) => {
    invalidateQuery("ACCOUNTDEVICECOUNT");
    return r.data;
  });

// TODO: For discussion. There is code in other spots that guards account-list-retrieval to only be attempted by APP_ADMINS. This was
// usually 15 lines of code - a local array in a controller with a subscriber to the account store to get the list only if the user has
// that role. It's a 1-liner if we do it this way, but is it correct for all cases?
export const useAccounts = () =>
  useQuery(QK_ACCOUNTS, () => (AppState.user?.role === "APP_ADMIN" ? getAllAccounts() : Promise.resolve([])));

export const useDeviceCount = () =>
  useQuery("ACCOUNTDEVICECOUNT", () => (AppState.user?.role === "APP_ADMIN" ? getDeviceCount() : Promise.resolve(undefined)));

export const getAccountInfoById = (accountId: any) =>
  Endpoint.get<IAccountInfo | null>(`api/users/accountInfo/${accountId}`).then((r) => {
    return r.data;
  });

export const useAccountInfoById = (accountId: any) =>
  useQuery(["ACCOUNTINFO", accountId], () => (accountId ? getAccountInfoById(accountId) : null));

export const integrateAccountWithStripe = (accountId: number | string) =>
  Endpoint.put<IAccountInfo>(`api/accounts/${accountId}`).then((r) => {
    invalidateQuery(["ACCOUNTINFO"]);
    return r.data;
  });

export const changeAccountDemo = (accountId: number | string, is_demo: boolean, demoStartDate: Date, demoEndDate: Date) =>
  Endpoint.put<IAccount>(`api/accounts/${accountId}/demo`, { is_demo: is_demo, demoStartDate, demoEndDate }).then((r) => {
    invalidateQuery(["ACCOUNTINFO"]);
    return r.data;
  });

export const extendAccountDemoEndDate = (accountId: number | string, newDemoEndDate: Date) =>
  Endpoint.put<IAccount>(`api/accounts/${accountId}/extendDemoEndDate`, { newDemoEndDate }).then((r) => {
    invalidateQuery(["ACCOUNTINFO"]);
    return r.data;
  });

/**
 * Transform a date that is passed as string into a date
 * @param value The date passed as string
 * @returns {Date | null} The Date object or null if invalid
 */
export const stringToDate = (value: string | null | undefined): Date | null => {
  if (!value || value === "") {
    return null;
  }

  const dateFormat = getUserDateFormat();
  const timeFormat = getTimeFormatValueForUser(true);

  let m = moment(value, `${dateFormat} ${timeFormat}`, true);
  if (!m.isValid()) {
    m = moment(value);
  }

  return m.isValid() ? m.toDate() : null;
};

export const markNotificationAsRead = async (notificationTypes: NotificationTypes[]) => {
  Endpoint.post("api/accounts/readNotifications", notificationTypes).then((response) => {
    return response.data;
  });
};
export const markFirmwareChangesRead = (accountId: number, firmware_changes_read: boolean) =>
  Endpoint.post(`api/accounts/${accountId}/read-firmware-changes`, { firmware_changes_read }).then((response) => {
    return response.data;
  });

export const updateAccountBillingInformation = async (data: IBillingInfo) => {
  Endpoint.put(`api/accounts/billing-info`, data).then((response) => {
    return response.data;
  });
};
