import React from "react";
import { useTranslation } from "react-i18next";
import { INotification } from "../../../Managers/Types";
import { styleAcknowledgementNotes } from "../../../Managers/NotificationService";

interface IResolutionStep {
  notification: INotification;
  alertResolutionNotes: string;
  setAlertResolutionNotes: React.Dispatch<React.SetStateAction<string>>;
  resolveAlert: () => void;
}

export const ResolutionStep: React.FC<IResolutionStep> = ({
  notification,
  alertResolutionNotes,
  setAlertResolutionNotes,
  resolveAlert,
}) => {
  const { t } = useTranslation(["alert_resolve", "alerts"]);

  const handleResolution = () => {
    setAlertResolutionNotes(alertResolutionNotes);
    resolveAlert();
  };

  return (
    <div className="notes-step">
      <div>
        <label className="input-label" htmlFor="protocol-info">
          {t("alert_resolve:alert_protocol")}
        </label>
        <textarea name="protocol-info" rows={4} className="input-textarea disabled input" readOnly>
          {notification.Alert.protocol || "No protocol provided."}
        </textarea>
      </div>
      <div>
        <label htmlFor="notes" className="input-label">
          {t("alert_resolve:notes")}
        </label>
        <div className="notes-list custom-scrollbar">
          {styleAcknowledgementNotes(notification.acknowledgement_notes).map((note: string, index) => (
            <div key={index} className="history-note" dangerouslySetInnerHTML={{ __html: note }} />
          ))}
        </div>

        <label className="input-label" htmlFor="resolution-note">
          {t("alert_resolve:resolution")}
        </label>
        <textarea
          name="resolution_note"
          rows={2}
          cols={80}
          className="input input-textarea"
          placeholder={t("alert_resolve:resolution_placeholder")}
          value={alertResolutionNotes}
          onChange={(e) => setAlertResolutionNotes(e.target.value)}
        />
        <div className="save-note-button-wrapper">
          <button className="btn btn-primary save-note-button" onClick={handleResolution}>
            {t("common:save")}
          </button>
        </div>
      </div>
    </div>
  );
};
