import * as yup from "yup";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { resetPassword, updateUser } from "../Managers/UsersService";
import { AppState, getUserDateFormat, getUserTimeFormat, refreshAppState, showAppModal, showSnackbar } from "../AppState";
import { FormFieldCheckbox, FormFieldSelect, FormFieldText, useScreenMode } from "../Components";
import { DateFormat, UserDateFormats, UserRoles, ValidationMessages, WindowSize } from "../Enums";
import { PhoneInput } from "../Components/PhoneInput";
import { ICountry } from "../Managers";
import { Modal } from "../Components/Modal";
import { useTranslation } from "react-i18next";
import { useLanguages } from "../Managers/LanguageService";
import { ILanguage } from "../Managers/Types";
import { changeLanguage } from "i18next";
import classNames from "classnames";
import "./ManageUserProfileModal.scss";
import { useCountries } from "../Managers/API";
import { DEFAULT_LANGUAGE } from "../i18n";
import { TimeFormat, UserTimeFormats } from "../Enums/TimeFormat";

interface IManageUserProfileFormProps {
  first_name: string;
  last_name: string;
  title?: string;
  phone: string;
  email: string;
  country?: ICountry;
  language: string;
  is_marketing_accepted?: boolean;
  apply_default_low_battery_alerts?: boolean;
  apply_default_transmitting_alerts?: boolean;
  is_imperial_default?: boolean;
  date_format: DateFormat;
  time_format: TimeFormat;
  secondary_email?: string;
  secondary_phone?: string;
  use_secondary_email?: boolean;
  use_secondary_email_for_marketing?: boolean;
  use_secondary_phone?: boolean;
}

export const ManageUserProfileModal: React.FC = observer(() => {
  const [isSendingPassword, setIsSendingPassword] = useState(false);
  const [languages, setLanguages] = useState<ILanguage[]>([]);
  const countryList = useCountries();
  const countries = countryList.data ?? [];
  const languageQuery = useLanguages();

  const { t } = useTranslation(["manage_user_profile", "common", "register"]);
  const mode = useScreenMode();

  useEffect(() => {
    setLanguages(languageQuery.data?.filter((l) => l.active) ?? []);
  }, [languageQuery.dataUpdatedAt]);

  const validationSchema = yup.object({
    first_name: yup.string().required(t(ValidationMessages.REQUIRED)),
    last_name: yup.string().required(t(ValidationMessages.REQUIRED)),
    title: yup.string(),
    phone: yup
      .string()
      .required(t(ValidationMessages.REQUIRED))
      .matches(/^[0-9]+$/, t(ValidationMessages.PHONE)),
    email: yup.string().email(t(ValidationMessages.EMAIL)).required(t(ValidationMessages.REQUIRED)),
    language: yup.string(),
    date_format: yup.string(),
    secondary_email: yup.string().email(t(ValidationMessages.EMAIL)),
    secondary_phone: yup.string().matches(/^[0-9]+$/, t(ValidationMessages.PHONE)),
  });

  if (!AppState.user) {
    return <></>;
  }

  const handleUpdateUser = (values: IManageUserProfileFormProps) => {
    if (!AppState.user) {
      return;
    }

    const shouldChangeLanguage = values.language !== AppState.user.language;

    updateUser({
      ...AppState.user,
      ...values,
      country_code: values.country?.code ?? "+1",
      phone_code: values.country?.dial_code ?? "+1",
      secondary_phone_code: values.country?.dial_code ?? "+1",
      use_secondary_email: values.use_secondary_email || false,
      use_secondary_email_for_marketing: values.use_secondary_email_for_marketing || false,
      use_secondary_phone: values.use_secondary_phone || false,
    })
      .then((r) => {
        if (shouldChangeLanguage) {
          changeLanguage(values.language);
        }
        console.log("Update user result", r);
        showAppModal(null);
        showSnackbar(t("manage_user_profile:update_success"), "success");
        return refreshAppState();
      })
      .catch((error) => {
        console.log("Update user error", error);
        showSnackbar(t("manage_user_profile:update_error"), "error");
      });
  };

  const handleResetPassword = () => {
    setIsSendingPassword(true);
    AppState.user &&
      resetPassword(AppState.user)
        .then((r) => {
          console.log("reset result", r);
          showSnackbar(t("manage_user_profile:password_reset_success"), "success");
        })
        .catch((e) => {
          console.log("Error resetting password", e);
          if (e.response && e.response.status === 404) {
            showSnackbar(t("manage_user_profile:password_reset_error_email_not_found"), "error");
          } else {
            showSnackbar(t("manage_user_profile:password_reset_error"), "error");
          }
        })
        .finally(() => setIsSendingPassword(false));
  };

  const initialValuesEdit: IManageUserProfileFormProps = {
    first_name: AppState.user?.first_name || "",
    last_name: AppState.user?.last_name || "",
    title: AppState.user?.title || "",
    phone: AppState.user?.phone || "",
    email: AppState.user?.email || "",
    country: countries.find((c) => c.code === AppState.user?.country_code),
    language: AppState.user?.language || DEFAULT_LANGUAGE,
    is_marketing_accepted: AppState.user?.is_marketing_accepted || false,
    apply_default_low_battery_alerts: AppState.user.Account?.apply_default_low_battery_alerts ?? false,
    apply_default_transmitting_alerts: AppState.user.Account?.apply_default_transmitting_alerts ?? false,
    is_imperial_default: AppState.user.Account?.is_imperial_default ?? true,
    date_format: getUserDateFormat(),
    time_format: getUserTimeFormat(),
    secondary_email: AppState.user?.secondary_email || "",
    secondary_phone: AppState.user?.secondary_phone || "",
    use_secondary_email: AppState.user?.use_secondary_email || false,
    use_secondary_email_for_marketing: AppState.user?.use_secondary_email_for_marketing || false,
    use_secondary_phone: AppState.user?.use_secondary_phone || false,
  };

  return (
    <Formik key={countries.length} initialValues={initialValuesEdit} validationSchema={validationSchema} onSubmit={handleUpdateUser}>
      {({ isSubmitting, isValid, values, submitForm, setFieldValue }) => {
        return (
          <Modal
            title={t("manage_user_profile:title")}
            buttons={
              <>
                <button className="btn btn-info" onClick={() => showAppModal(null)} disabled={isSubmitting}>
                  {t("common:close")}
                </button>
                <button className="btn btn-primary" disabled={!isValid || isSubmitting} onClick={submitForm}>
                  {isSubmitting ? <i className="fa fa-circle-o-notch fa-spin" /> : <></>}
                  {t("common:save_changes")}
                </button>
              </>
            }>
            <form>
              <div className="row">
                <FormFieldText
                  required={true}
                  className="col-sm-6"
                  name="first_name"
                  label={t("manage_user_profile:first_name")}
                  placeholder={t("manage_user_profile:first_name")}
                />
                <FormFieldText
                  required={true}
                  className="col-sm-6"
                  name="last_name"
                  label={t("manage_user_profile:last_name")}
                  placeholder={t("manage_user_profile:last_name")}
                />
              </div>

              <div className="row">
                <FormFieldText
                  className="col-sm-6"
                  name="title"
                  label={t("manage_user_profile:user_title")}
                  placeholder={t("manage_user_profile:user_title")}
                />
                <FormFieldSelect
                  className="col-sm-6"
                  label={t("manage_user_profile:language")}
                  name="language"
                  options={languages.map((l) => ({
                    label: t(`common:language.${l.code}`),
                    value: l.code,
                  }))}
                />
              </div>

              <div className="row">
                <PhoneInput
                  required={true}
                  className="col-sm-6"
                  label={t("manage_user_profile:phone_number")}
                  countryName="country"
                  name="phone"
                />
                <FormFieldText
                  required={true}
                  className="col-sm-6"
                  name="email"
                  label={t("manage_user_profile:email_address")}
                  placeholder={t("manage_user_profile:email_address")}
                />
              </div>

              <div className="row">
                <PhoneInput
                  className="col-sm-6"
                  label={t("manage_user_profile:secondary_phone_number")}
                  countryName="country"
                  name="secondary_phone"
                />
                <FormFieldText
                  className="col-sm-6"
                  name="secondary_email"
                  label={t("manage_user_profile:secondary_email_address")}
                  placeholder={t("manage_user_profile:secondary_email_address")}
                />
              </div>

              <div className="row">
                <FormFieldSelect
                  onChange={(format) => setFieldValue("date_format", format)}
                  options={UserDateFormats.map((format) => ({ label: format, value: format }))}
                  name="date_format"
                  className="col-sm-6"
                  label={t("manage_user_profile:date_format")}
                />
                <FormFieldSelect
                  onChange={(format) => setFieldValue("time_format", format)}
                  options={UserTimeFormats.map((format) => ({ label: format, value: format }))}
                  name="time_format"
                  className="col-sm-6"
                  label={t("manage_user_profile:time_format")}
                />
              </div>

              <div>
                <FormFieldCheckbox
                  onChange={() => setFieldValue("is_marketing_accepted", !values.is_marketing_accepted)}
                  required={false}
                  name="marketingChecked"
                  checked={values.is_marketing_accepted ?? false}
                  label={t("register:steps.account.marketing")}
                />
                <p className="info-subtitle" dangerouslySetInnerHTML={{ __html: t("manage_user_profile:opt_out_message") }} />
              </div>

              <div className="col-sm-12 manage-user-row-to-left">
                <FormFieldSelect
                  options={[
                    {
                      value: false,
                      label: `${t("manage_user_profile:primary_email")} - ${AppState.user?.email}`,
                    },
                    {
                      value: true,
                      label: `${t("manage_user_profile:secondary_email")} - ${AppState.user?.secondary_email}`,
                    },
                  ]}
                  name="use_secondary_email"
                  label={t("manage_user_profile:notification_email")}
                  onChange={() => setFieldValue("use_secondary_email", !values.use_secondary_email)}
                  value={values.use_secondary_email}
                  className="col-sm-6"
                  disabled={!AppState.user?.secondary_email}
                  style={{ paddingLeft: 0 }}
                  labelTooltip={
                    !AppState.user?.secondary_email
                      ? t("manage_user_profile:notification_email_disabled_tooltip")
                      : t("manage_user_profile:notification_email_tooltip")
                  }
                />

                <FormFieldSelect
                  options={[
                    {
                      value: false,
                      label: `${t("manage_user_profile:primary_email")} - ${AppState.user?.email}`,
                    },
                    {
                      value: true,
                      label: `${t("manage_user_profile:secondary_email")} - ${AppState.user?.secondary_email}`,
                    },
                  ]}
                  name="use_secondary_email_for_marketing"
                  label={t("manage_user_profile:marketing_email")}
                  onChange={() => setFieldValue("use_secondary_email_for_marketing", !values.use_secondary_email_for_marketing)}
                  value={values.use_secondary_email_for_marketing}
                  className="col-sm-6"
                  disabled={!AppState.user?.secondary_email}
                  style={{ paddingLeft: 0 }}
                  labelTooltip={
                    !AppState.user?.secondary_email
                      ? t("manage_user_profile:marketing_email_disabled_tooltip")
                      : t("manage_user_profile:marketing_email_tooltip")
                  }
                />
              </div>

              <div className="col-sm-12 manage-user-row-to-left">
                <FormFieldSelect
                  options={[
                    {
                      value: false,
                      label: `${t("manage_user_profile:primary_phone")} - ${AppState.user?.phone}`,
                    },
                    {
                      value: true,
                      label: `${t("manage_user_profile:secondary_phone")} - ${AppState.user?.secondary_phone}`,
                    },
                  ]}
                  disabled={!AppState.user?.secondary_phone}
                  name="use_secondary_phone"
                  label={t("manage_user_profile:notification_phone")}
                  labelTooltip={!AppState.user?.secondary_phone ? t("manage_user_profile:secondary_phone_disabled_tooltip") : undefined}
                  onChange={() => setFieldValue("use_secondary_phone", !values.use_secondary_phone)}
                  value={values.use_secondary_phone}
                  className="col-sm-6"
                  style={{ paddingLeft: 0 }}
                />
              </div>

              <div style={{ marginTop: "1rem" }}>
                <p className="input-label u-mobile-hide">{t("manage_user_profile:reset_password")}</p>
                <button
                  className={classNames("btn", mode === WindowSize.MOBILE ? "btn-plain u-text-teal" : "btn-primary")}
                  onClick={handleResetPassword}
                  disabled={isSendingPassword}>
                  {isSendingPassword ? <i className="fa fa-spin fa-circle-o-notch" /> : <i className="fa fa-repeat u-mobile-only" />}
                  {t("manage_user_profile:send_reset_password_link").toUpperCase()}
                </button>
                <br />
              </div>
              {(AppState.user?.role === UserRoles.APP_ADMIN || AppState.user?.role === UserRoles.ADMIN) && (
                <div className="account-settings-section">
                  <hr />
                  <div className="type-large-regular">{t("manage_user_profile:account_settings")}</div>

                  <div className="col-sm-12 manage-user-row-to-left">
                    <FormFieldSelect
                      className="col-sm-6"
                      options={[
                        { value: true, label: t("common:imperial") },
                        { value: false, label: t("common:metric") },
                      ]}
                      name="is_imperial_default"
                      style={{ paddingLeft: 0 }}
                      label={t("manage_user_profile:default_measurement_system")}
                      onChange={() => console.log(values.is_imperial_default)}
                    />
                  </div>

                  <div className="row col-sm-12">
                    <FormFieldCheckbox
                      onChange={() => setFieldValue("apply_default_transmitting_alerts", !values.apply_default_transmitting_alerts)}
                      required={false}
                      name="apply_default_transmitting_alerts"
                      checked={values.apply_default_transmitting_alerts ?? false}
                      label={t("manage_user_profile:default_not_transmitting_alarm")}
                    />
                  </div>
                  <div className="row col-sm-12">
                    <FormFieldCheckbox
                      onChange={() => setFieldValue("apply_default_low_battery_alerts", !values.apply_default_low_battery_alerts)}
                      required={false}
                      name="apply_default_low_battery_alerts"
                      checked={values.apply_default_low_battery_alerts ?? false}
                      label={t("manage_user_profile:default_low_battery_alarm")}
                    />
                  </div>
                </div>
              )}
            </form>
          </Modal>
        );
      }}
    </Formik>
  );
});
