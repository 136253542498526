import React from "react";
import { CircularProgress } from "@mui/material";
import { NotesTable } from "./NotesTable";
import { ISensorDataNote } from "../../../../Managers/Types";
import { deleteSensorDataNote, useSensorDataNotes } from "../../../../Managers/API";
import { useScreenMode } from "../../../../Components";
import { WindowSize } from "../../../../Enums";
import { MobileNoteCards } from "./MobileNoteCards";
import { showAppModal } from "../../../../AppState";
import { ConfirmModal } from "../../../../Modals";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";

interface INotesTableController {
  handleEditNote: (note: ISensorDataNote) => void;
  sensorId: number;
  returnToDeviceDetailModal: () => void;
}

export const NotesTableController: React.FC<INotesTableController> = ({ handleEditNote, sensorId, returnToDeviceDetailModal }) => {
  const { data: notes, isFetching } = useSensorDataNotes(sensorId);
  const mode = useScreenMode();
  const queryClient = useQueryClient();
  const { t } = useTranslation(["device_detail", "common"]);

  const handleDelete = (note: ISensorDataNote) => {
    showAppModal(
      <ConfirmModal
        header={t("device_detail:notes.delete_dialog.title")}
        children={<p>{t("device_detail:notes.delete_dialog.body")}</p>}
        confirmText={t("common:delete")}
        confirmButtonType="btn-danger"
        onConfirm={() => {
          deleteSensorDataNote(note._id).then(async () => {
            await queryClient.invalidateQueries(["SENSOR_DATA_NOTES", sensorId]);
            returnToDeviceDetailModal();
          });
        }}
        onCancel={returnToDeviceDetailModal}
      />,
    );
  };

  const noContent = (notes ?? []).length === 0;

  if (isFetching) {
    return <CircularProgress />;
  }

  if (noContent) {
    return <></>;
  }

  if (mode === WindowSize.MOBILE) {
    return <MobileNoteCards notes={notes} handleDelete={handleDelete} handleEditNote={handleEditNote} />;
  }

  return <NotesTable notes={notes} handleDelete={handleDelete} handleEditNote={handleEditNote} />;
};
