import { getSensorDataNotes } from "../API";
import autoTable from "jspdf-autotable";
import { ISensor, ISensorDataNote } from "../Types";
import { TFunction } from "i18next";
import { getTimeFormatValueForUser } from "../../Enums/TimeFormat";
import { getUserDateFormat } from "../../AppState";
import moment from "moment";
import jsPDF from "jspdf";
import { IChartDataProps } from "../../Modals/DeviceReport/DeviceReportModal";

interface ISensorWithNotes {
  sensor: ISensor;
  notes: Array<ISensorDataNote>;
}

export async function includeSensorDataNotesInPDF(doc: jsPDF, deviceId: number, sensors: IChartDataProps[], t: TFunction) {
  const sensorWithNotesList: Array<ISensorWithNotes> = await getSensorWithNotesList(deviceId, sensors);

  for (const sensorWithNotes of sensorWithNotesList) {
    const { headers, data } = await prepareSensorDataNotesData(sensorWithNotes, t);

    doc.addPage("a4", "landscape");
    doc.setFontSize(18);
    doc.text(t("export:sensor_data_note.header", { sensorName: sensorWithNotes.sensor.Sensor_type.name }), 40, 40);
    autoTable(doc, {
      head: [headers.map((h) => h.header)],
      body: data,
      startY: 70,
      margin: {
        top: 60,
        bottom: 70,
        left: 35,
      },
    });
  }
}

export async function includeSensorDataNotesInXLSX(
  deviceId: number,
  sensors: IChartDataProps[],
  t: TFunction,
  xlsxSensorDataNotes: (string | number | null)[][],
) {
  const sensorWithNotesList: Array<ISensorWithNotes> = await getSensorWithNotesList(deviceId, sensors);

  for (const sensorWithNotes of sensorWithNotesList) {
    const { headers, data } = await prepareSensorDataNotesData(sensorWithNotes, t);
    xlsxSensorDataNotes.push([t("export:sensor_data_note.header", { sensorName: sensorWithNotes.sensor.Sensor_type.name })]);
    xlsxSensorDataNotes.push(headers.map((header) => header.header));
    xlsxSensorDataNotes.push(...data);
  }
}

async function prepareSensorDataNotesData(sensorWithNotes: ISensorWithNotes, t: TFunction) {
  const timeFormat = getTimeFormatValueForUser();
  const dateFormat = getUserDateFormat();

  const headers = [
    { header: t("export:sensor_data_note.start_time"), dataKey: "startTime" },
    { header: t("export:sensor_data_note.end_time"), dataKey: "endTime" },
    { header: t("export:sensor_data_note.note"), dataKey: "note" },
  ];

  const data = sensorWithNotes.notes.map((note) => [
    moment(note.startTime).format(`${dateFormat} ${timeFormat}`),
    moment(note.endTime).format(`${dateFormat} ${timeFormat}`),
    note.content,
  ]);

  return { headers: headers, data: data };
}

function getSensorWithNotesList(deviceId: number, sensors: IChartDataProps[]): Promise<Array<ISensorWithNotes>> {
  const deviceSensors = sensors.filter((sensor) => sensor.sensor.DeviceId === deviceId);

  const sensorWithNotesPromises: Array<Promise<ISensorWithNotes>> = deviceSensors.map(async (sensor) => ({
    sensor: sensor.sensor,
    notes: await getSensorDataNotes(sensor.sensor._id),
  }));

  return Promise.all(sensorWithNotesPromises);
}
